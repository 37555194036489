$duration: .2s;
$easing: $Power4EaseInOut;

.view__deepdives{
    align-items: center;
    justify-content: center;

    display: flex;
    flex-direction: column;

    @media #{$mobile} {
        padding-top: 90px;
    }
    @media #{$mobile}{
        padding-left:10px;
        padding-right:10px
    }

    .cursor--loading & {
        cursor: none; // leave the loading cursor ALONE !
    }

    .floating-text{
        position: absolute;
        top: 60px;
        left: 0;
        
        opacity: 0;
        user-select: none;
        transform: none;
        overflow: hidden;

        @media #{$mobile}{
            display: none;
        }
    }
}

.deepdives__title{
    position: relative;

    margin-top: 20vh;

    font-size: 35px;
    line-height: 100%;
    color: white;

    opacity: 0;
    transform: translateY(20px);

    @media #{$mobile}{
        margin-top: 100px;
    }
}

.deepdives__subtitle{
    position: relative;

    margin-top: 20px;

    font-size: 15px;
    line-height: 100%;
    color: white;

    opacity: 0;
    transform: translateY(20px);

    @media #{$tablet}{
        text-align: center;
    }
}

.deepdives__list{
    width: 72%;
    margin: 80px auto;
    pointer-events: none;
    

    @media #{$mobile}{
        width: 80%;
        margin: 50px auto 80px;
    }

    @media #{$cell}{
        width: 85%;
        margin: 50px auto 0px;
    }
}

.deepdives__item{
    position: relative;

    height: calc(100vw / 3.5);
    margin-bottom: 10vh;
    lost-column: 1/3 3 5%;

    opacity: 0;
    transform: translateY(30px);

    &:nth-of-type(3n + 2){
        top: 50px;

        @media #{$mobile}{
            top: 0px;
        }
    }

    @media #{$mobile}{
        &:nth-of-type(3n + 2){
            top: 50px;
        }
    }

    @media #{$cell} {
        height: calc(100vw / 2);
        lost-column: 1/2 2 8%;
        &:nth-of-type(3n + 2){
            top: auto
        }
        &:nth-of-type(2n){
            top: 80px;
        }
    }

    .deepdives__link{
        will-change:transform;
        position: relative;

        display: block;
        height: 100%;
        z-index: 4;
        pointer-events: auto;
    }

    .deepdives__image {
        position: absolute;
        top: 0;
        left: 0;

        display: block;
        width: 100%;
        height: 100%;

        transition: transform .4s ease;
        border-radius: 10px;
        pointer-events: none;

        cursor: url("../assets/images/cursor/pointer.png"), pointer;
        transform: translate3d(0, 0, 0);

        .deepdives__black-white {
            width: 100%;
            height: 100%;
            pointer-events: none;

            img {
                width: 100%;
                height: 100%;
                border-radius: 10px;
            }
        }

        .deepdives__duotone {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            opacity: 0;

            transition: opacity 0.5s ease;
            pointer-events: none;
            
            img {
                width: 100%;
                height: 100%;
                border-radius: 10px;
            }
        }
    }

    .item__title{
        position: relative;
        z-index: 2;

        margin-bottom: 10px;
        padding-bottom: 10px;

        font-weight: 500;
        font-size: 26px;
        color: white;

        transform: translateZ(0);

        @media #{$mobile}{
            font-size: 15px;
        }

        &:after{
            position: absolute;
            bottom: 0;

            display: block;
            width: 35px;
            height: 1px;

            background-color: white;
            content: '';
            transition: opacity $duration $easing $duration * 2, transform $duration $easing $duration * 2;

            transform-origin: left top;
        }
    }

    .item__subtitle{
        position: relative;

        display: block;
        padding-bottom: 10px;

        font-size: 19px;
        color: white;
        font-weight: 300;

        opacity: 0;
        transition: opacity $duration $easing, transform $duration $easing;
        transform: translateX(15px);

        @media #{$mobile}{
            display: none;
        }

        &:after{
            position: absolute;
            bottom: 0;

            display: block;
            width: 35px;
            height: 1px;

            //opacity: 0;
            background-color: white;
            content: '';
            transition: opacity $duration $easing, transform $duration $easing;
            //transform: translateX(100%);
            transform: scale(0,1);

            transform-origin: right top;
        }
    }

    .deepdives__infos{
        position: absolute;
        bottom: 60px;
        left: 0;
        z-index: 4;
        pointer-events: none;

        width: 100%;

        transition: transform .4s $easing;
        transform: translateX(-20px);

        @media #{$mobile}{
            transform: translateX(-10px);
            bottom: 0px;
        }
    }
}

.desktop .deepdives__item:hover {

    .deepdives__infos {
        transition: transform .3s ease-in-out;
        transform: translate(-20px,-50px);
    }

    .deepdives__image {
        transform: scale(1.05);

        .deepdives__duotone {
            opacity: 1;
        }
    }

    .item__title{
        &:after{
            transition: opacity $duration $easing, transform $duration $Power3EaseOut;
            transform: scale(0,1);
        }
    }

    .item__subtitle{
        opacity: 1;
        transition: opacity $duration $easing $duration * 2, transform $duration $easing $duration * 2;
        transform: translateX(0);

        &:after{
            //opacity: 1;
            transition: opacity $duration $easing $duration * 2 + .1, transform $duration $Power3EaseOut $duration * 2 + .3;
            //transform: translateX(0);
            transform: scale(1,1);
        }
    }
}
