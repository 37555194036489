/**
* Colors
*/

$c-text : #656565;
$c-white : #fff;
$c-primary : #e72c9e;
$c-secondary : #d5252f;
$c-pink: #e82360;

/*
* Responsive
*/

$large: 1440px;
$desktop: 1280px;
$tablet-h: 1024px;
$tablet-w : 768px;

// cursors
$pointer: url('../assets/images/cursor/pointer.png'), pointer;
$grab: url('../assets/images/cursor/grab.png'), grab;
$grabbing: url('../assets/images/cursor/grabbing.png'), grabbing;

/*
* MQ
*/
$cell : "only screen and (max-width:480px)";
$mobile : "only screen and (max-width:768px)";
$notMobile : "only screen and (min-width:769px)";
$tablet : "only screen and (max-width:1024px)";
$smallDesktop: "only screen and (max-width:1280px)";
$bigDesktop: "only screen and (min-width:1440px)";
$hugeDesktop: "only screen and (min-width:1980px)";

/*
* Font-size
*/
$fs-20 : 20px;
$fs-13 : 13px;
$fs-12 : 12px;

/*
* Containers width
*/
$container-content-width : 1220px;
$container-content-padding : 80px;

$distorsionShadow: 2px 0px 2px rgba(0, 255, 240, 0.4), -2px 0px 2px rgba(232, 35, 96, 0.4);

@lost rounder 100;