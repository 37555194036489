.full-text{
    display: flex;
    
    // MarginChange
	// margin-bottom: 100px;
    margin-bottom: 80px;

    @media #{$mobile}{
        margin-bottom:40px;
    }

    &>div{
        lost-column:8/12 1;
        lost-offset:2/12;
        @media #{$mobile}{
            lost-column:1/1 1;
            lost-offset:0;
        }
    }
}