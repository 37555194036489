/* ============================*/
/* ==[ Plyr Video player Style ]== */
/*=============================*/
.plyr{
	.plyr__controls button:hover{
		background: transparent;
	}

	.plyr__progress--played{
		height: 8px;
	}

	.plyr__progress{
		position: absolute !important;
		bottom: 0 !important;
		left: 0 !important;

		width: 100% !important;
		margin: 0 !important;
	}

	.plyr__progress--seek{
		height: 10px !important;
	}

	input[type=range]::-webkit-slider-thumb{
		opacity: 0;
	}

	.plyr__time--current{
		font-weight: 700;
	}

	.plyr__play-large{
		display: none;
	}

	.plyr__controls{
		justify-content: space-between;

		display: flex;
		padding: 19px 10px 10px;

		background: none;
		&:before{
			position: absolute;
			top: 0;
			left: 0;

			width: 100%;
			height: 100%;

			background: linear-gradient(transparent, rgba(0, 0, 0, .3));
			content: '';
			transition: opacity .3s ease;
		}

		button,
		.plyr__time{
			transition: opacity .3s ease;
		}

		.plyr__volume{
			display: none !important;
		}

		.plyr__time{
			position: absolute;
			right: 50px;
			bottom: 17px;
			span{
				color: #fff;
			}
		}

		.plyr__progress--buffer{
			background: rgba(0,0,0,.5);
		}

		.plyr__progress--buffer,
		.plyr__progress--played{
			margin-top: -3px !important;

			border-radius: 0px !important;
		}
	}

	progress[value]::-webkit-progress-bar{
		border-radius: 0px;
	}

	progress[value]::-webkit-progress-value{
		border-radius: 0px;
	}

	&.plyr--hide-controls{
		.plyr__controls{
			opacity: 1;

			&:before{
				opacity: 0;
			}
		}
		
		button,
		.plyr__time{
			opacity: 0;
		}
	}
}
