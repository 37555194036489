.view.view__notfound {
    flex-direction: column;
	align-items: center;
	justify-content: center;

	display: flex;
	width: 100vw;
    height: 100vh;

	.home__button {
		position: relative;
		right: auto;
		bottom: auto;

		span {
			pointer-events: none;
		}
	}
}

.notfound__title {
	color:#fff;
	font-size: 120px;
	text-shadow: $distorsionShadow;
	text-align: center;

	opacity: 0;
}

.notfound__text {
	color:#fff;
	font-size: 20px;

	opacity: 0;
}