/* ============================*/
/* ==[ Block Two col ]== */
/*=============================*/
.two-col{
	align-items: center;

    display: flex;
    
	// MarginChange
	// margin-bottom: 100px;
    margin-bottom: 80px;
    
	lost-utility: clearfix;
	
	@media #{$mobile}{
		flex-direction: column;
		margin-bottom: 0px;
	}
}

.two-col__image{
    text-align: center;
    position:relative;
    img{
        width:100%;
    }
}

@media #{$mobile}{
	.two-col__image img{
		width: 100%;
	}
}
.two-col__video video{
    background-color:#000;
}

.two-col__video .plyr-player{
    max-width:100%;
}

.two-col__text,
.two-col__video,
.two-col__image{
	lost-column: 1/2 2 60px;

	@media #{$mobile}{
		margin-bottom: 40px;
		lost-column: 1/1 1 0;
	}
}

.two-col__text{
	h3{
		margin-bottom: 20px;

		@include subTitle;
	}

	p{
		@include regularText;
	}

	.two-col__quote{
		@include bigQuote();
	}

	.two-col__quote + p{
		margin-top: 50px;

		@media #{$mobile}{
			margin-top: 20px;
		}
	}

	p + .two-col__quote{
		margin-top: 60px;

		@media #{$mobile}{
			margin-top: 30px;
		}
	}
}
