$easing: $ExpoEaseOut;
$duration: 0.3s;

.button {
    justify-content: center;
    align-items: center;
    
    display: flex;
    height: 42px;
    
    border-radius: 21px;

    pointer-events: none;
    cursor: $pointer;

    .button__bkg {
        position: absolute;
        top: 0;
        left: 0;
        
        width: 100%;
        height: 100%;

        transition: transform $duration $easing;
    }

    .button__label {
        position: relative;
        justify-content: center;
        align-items: center;
        z-index: 2;
        
        display: flex;
        height: 42px;
        padding-left: 0;
        padding-right: 22px;
        padding-top: 2px;

        color: rgba(0, 0, 0, 0);
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;

        overflow: hidden;
        pointer-events: none;
        white-space: nowrap;
        user-select: none;

        transition: transform $duration $easing, color 0s $easing;
    }

    .button__content {
        display: flex;

        transform: translate3d(calc(50% - 33px), 0, 0);
    }

    &:hover {
        // .button__content {
        //     transform: translate3d(0, 0, 0);       
        // }

        .button__label {
            color: rgba(0, 0, 0, 1);
            transition: transform $duration $easing, color $duration $easing 0.1s;
        }

        .button__bkg {
            // transform: scale(1.2);
        }

        .button__hit-area {
            width: 100% !important;
        }
    }

    .button__icon {
        justify-content: center;
        align-items: center;

        display: flex;
        width: 64px;
        height: 42px;

        z-index: 2;
        pointer-events: none;
    }

    .button__close {
        position: absolute;
        top: 50%;
        left: 50%;

        width: 10px;
        height: 10px;
        margin-top: -5px;
        margin-left: -5px;

        transition: transform 0.3s $ExpoEaseOut 0.3s;
        transform: rotate(45deg);

        pointer-events:none;

        &__line {
            position:absolute;
            width:14px;
            height:2px;

            background: black;

            top:4px;
            left:-2px;

            transform: scale(0,0);
            transition: transform .3s $ExpoEaseInOut;
            border-radius: 2px;

            &:nth-child(2) {
                top:-2px;
                left:4px;
                width:2px;
                height:14px;
            }
        }
    }

    .button__hit-area {
        background-color:red;
        opacity: 0;
        position:absolute;
        width:100%;
        height:100%;

        pointer-events: auto;
    }

    &.open {
        .button__close {
            transition: transform 0.3s $ExpoEaseOut;

            &__line {
                transform: scale(1,1);
            }
        }

        .button__label {
            color: rgba(0, 0, 0, 0);

            transition: transform $duration $easing, color $duration $easing;
        }

        &:hover {
            .button__icon__close{
                transition: transform .2s ease-out;
                transform: rotate(-45deg);
            }
        }
    }
}

.button:hover {
    .button__bkg {
        // transform: scale(1.2);

        .button__bkg-center {
            // transform: scale(1.1);
        }

        .button__bkg-left {
            // transform: scale(1.1);
        }

        .button__bkg-right {
            // transform: scale(1.1);    
        }
        
    }

    .button__label:after {
        // transform: scale(1.1);   
    }
}

.button.open {

    .button__icon {
        background: rgba(1, 1, 1, 0);
    }

    .button__bkg-left {
        // transform: translate3d(12px, 0, 0);
    }

    .button__bkg-center {
        transform: scaleX(0);
    }

    .button__bkg-right {
        // transform: translate3d(-12px, 0, 0);
    }

}

.button.button--hover:not(.open) {
    .button__label {
        // transform: translate3d(calc(100% - 66px), 0, 0);
    }
}

.button--readmore {
    // transform: translate3d(-34px, 0, 0);
}

.button--readmore .button__icon {
    flex-direction: column;
    justify-content: center;
}

.readmore__line {
    width: 15px;
    height: 2px;
    margin-bottom: 2px;

    background: black;
    
    &.readmore__line--small {
        width: 8px;
        margin-left: -7px;
        margin-bottom: 0px;
    }

    transition: transform .25s ease-out; 
}