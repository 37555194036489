/* ============================*/
/* ==[ Full image trace ]== */
/*=============================*/
.block__full-image-trace{
	position: relative;

	height: 800px;
	margin-bottom: 80px;
	margin-top: 150px;
	
	@media #{$mobile}{
		height: 115vw;
		margin-bottom: 40px;
	}

	img{
		position: relative;
		left: calc((100vw - #{$container-content-width} + #{$container-content-padding} ) / -2);

		width: 100vw;
		max-width: none;
		height: 100%;

		object-fit: cover;

		@media screen and (max-width: $container-content-width){
			left: -20px;

			width: 100vw;
		}
	}

	.floating-text{
		position: absolute;
		z-index: 3423;
		left: calc((100vw - #{$container-content-width} + #{$container-content-padding} ) / -2);

		width: 100vw;
		height: 250px;

		transform: translateY(-120px);
		
		h2{
			padding-top:40px;
		}
	}

	.infinite-lines{
		position: absolute;
		top: 0;
		left: calc((100vw - #{$container-content-width} + #{$container-content-padding} ) / -2);

		@media screen and (max-width: $container-content-width){
			left: -20px;
			width: 100vw;
		}
	}
}
