.view__educate{

	// fallback
	body.fallback & {
		background-size: cover;
		min-height: 100vh;
	}

	.introduction{
		margin-bottom: 50px;
	}
	.educate__list{
		a{
			display: block;

			.desktop &:hover{
				span:before{
					opacity: 1;
					transform: translateY(0);
				}
			}
		}

		a p{
			margin-bottom: 10px;

			line-height: 1.2em;
		}

		a,
		a p,
		a span{
			font-size: 35px;
			font-weight: 400;
			
			body:not(.ie) &{
				background: linear-gradient(to right,#d03b62,#e72c9f);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}

			body.ie &{
				color:$c-primary;
			}

			@media #{$mobile}{
				font-size: 25px;
			}
		}

		a span{
			position: relative;
			display: inline-block;

			&:before{
				position: absolute;
				bottom: 0;

				display: block;
				width: 100%;
				height: 3px;

				opacity: 0;
				background: linear-gradient(to right,#d03b62,#e72c9f);
				content: '';
				transition: opacity .4s ease,transform .4s ease;
				transform: translateY(3px);
				
				body.ie &{
					background: $c-primary;
				}
			}
		}
	}

	.main__content h1{
		opacity: 0;
		transform: translateY(-60px);
	}

	.introduction{
		opacity: 0;
		transform: translateY(50px);
	}

	.educate__list div{
		opacity: 0;
		transform: translateY(50px);
	}
}
