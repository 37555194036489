/* ============================*/
/* ==[ Block full video ]== */
/*=============================*/
.full-video{
	position: relative;
	align-items: center;

	display: flex;
    height: 579px;
    width: 90%;
    margin: auto;

	// MarginChange
	// margin-bottom: 100px;
    margin-bottom: 80px;
    
	lost-utility: clearfix;

	@media #{$mobile}{
		flex-direction: column;
		height: auto;
        margin: 60px 0 40px;
        width:100%;
	}

	.plyr__progress--buffer{
		background: rgba(0,0,0,.1) !important;
	}

	.full-video__text{
		position: absolute;
        lost-column: 1/2 1 60px;
        &.right{
		    lost-offset: 1/2;
        }
        &.left{
            left:30px;
        }

		pointer-events: none;

		@media #{$mobile}{
			position: relative;
			left:0;
			lost-column: 1/1 1 0;
			lost-offset: 0;
			order: 1;
			margin-right: 0 !important;
		
		}

		.text__para{
			position: relative;

			width: 95%;

			@include videoQuote;

			&:before{
				position: absolute;
				top: 90%;
				left: -23%;

				font-size: 480px;

				opacity: .1;
				content: '“';

				@media #{$mobile}{
					top: 70%;
				}
			}
		}

		.text__source{
			position: relative;

			float: right;
			margin-top: 40px;

			font-size: $fs-13;
			font-weight: 300;
			text-transform: uppercase;

			&:before{
				position: absolute;
				top: 42%;
				left: -120px;

				width: 100px;
				height: 1px;

				opacity: .2;
				background-color: #000;
				content: '';
				transform: translateY(-50%);
			}
		}
	}

	.full-video__video{
		width: 100%;

		@media #{$mobile}{
			order: 2;
		}

		video{
			width: 100%;
			height: 100%;

            object-fit: cover;
            background-color:#000;
		}
	}
}
