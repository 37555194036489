.view__povs{
	align-items: center;
	justify-content: space-around;

	display: flex;
	height: 100vh;
}

.povs__link{
	text-transform: uppercase;
	color: white;
}
