/* ========================*/
/* ==[ Wysiwyg Style + Infobox ]== */
/*========================*/
.wysiwyg{
	p{
		vertical-align: top;

		@include regularText;
	}

	p + p{
		margin-top: 30px;
	}

	h3{
		color: inherit;
		margin-bottom: 20px;
		font-size: 20px;
		font-weight: 500;
		line-height: 1.4em;
	}
	p + h3{
		margin-top: 30px;	
	}

	.infobox{
		position: relative;

		font-weight: 500;
		color: $c-primary;

		cursor: pointer;
        white-space: nowrap;
        
        font-size:15px;
        line-height: 1em;

        .view__deepdive--closed &{
            pointer-events: none;
        }

		span{
            color: inherit;
            font-size:16px;
            @media #{$mobile}{
                font-size:13px;
            }
		}

		.glossary__box{
			position: absolute;
			z-index: 12;
			bottom: 35px;
			left: 50%;

			display: block;
			width: 430px;
			padding: 15px;


			font-size: $fs-12;
			font-weight: 400;
			line-height: 1.5em;
			color: #fff;

			opacity: 0;
			border-radius: 5px;
			background-color: $c-primary;
			transform: translate(-50%,20px);
			white-space: normal;
			pointer-events: none;

			@media #{$tablet}{
				width: 300px;
			}

			@media #{$mobile}{
				width: 200px;
				padding: 8px;
			}

			&.left{
				left: -10px;

				transform: translateY(20px);

				.triangle{
					left: 40px;
                }
                
                @media #{$tablet}{
                    // transform: translate(-50%,20px);
                    // left:calc(50% - 70px);
                    .triangle{
                        // display:none;
                    }
                }
			}

			&.right{
				right: -23px;
				left: auto;

				transform: translateY(20px);

				.triangle{
					right: 40px;
					left: auto;
                }

                @media #{$tablet}{
                    // transform: translateX(-50%,20px);
                    // left:calc(50% - 70px);
                    .triangle{
                        // display:none;
                    }
                }
			}

			.triangle{
				position: absolute;
				bottom: -7px;
				left: 50%;

				width: 0;
				height: 0;

				border-top: 7px solid transparent;
				border-right: 7px solid transparent;
				border-left: 7px solid transparent;
				content: '';
				transform: translateX(-50%);
			}
		}

		.svg-wrapper{
			// position: relative;
			top: 4px; 
			align-items: center;
			justify-content: center;

            display: inline-flex;
            // font-size: 0;
            display: inline;
			// width: 22px;
			// height: 22px;

			body.ie &{
				display: inline-block;
			}

			// @media #{$tablet}{
			// 	top: 0;
            //     position: relative;
			// 	// width: 16px;
			// 	// height: 16px;
			// 	width: 0px;
			// 	height: 0px;
			// 	// display:none;
			// }

			.circle{
				position: absolute;

				width: calc(100% - 2px);
				height: calc(100% - 2px);

				border: 2px $c-primary solid;
				border-radius: 50%;
				background: #fff;
				content: '';
			}
		}
		
		svg{
			position: relative;

			width: 20px;
			height: 20px;
			
			body.ie &{
				position:relative;
				top:-2px;
			}

			@media #{$mobile}{
				width: 14px;
				height: 14px;
			}
		}
    }
    
    ul{
        font-size: 16px;
        list-style: none; /* Remove list bullets */
        padding: 0;
        margin: 10px 0;
        li { 
            // padding-left: 16px; 
            color:#656565;
            font-weight: 300;
            margin-bottom: 20px;
            line-height: 2em;

        }
        
        li:before {
            content: "•"; /* Insert content that looks like bullets */
            padding-right: 8px;
        }
    }
}
