.header{
    position: fixed;
    z-index: 81;
    top: 45px;
    left: 0;
    align-items: center;
    justify-content: space-between;

    display: flex;
    // overflow: hidden;
    width: 100vw;
    height: 94px;

    transition: background-color .3s ease;
    pointer-events: none;

    canvas.active{
        background-color: #000;
    }

    @media #{$mobile}{
        z-index: 105;
        // top:45px;

        pointer-events: auto;
    }
}

.header__background{
    position: absolute;
    top: 0;

    width: 100%;
    height: 94px;

    pointer-events: none;

    .fallback &{
        display: none;
        @media #{$mobile}{
            display:block;
        }
    }
}

.header__background--fallback{
    display: none;
    opacity: 0;

    .fallback &{
        position: absolute;
        z-index: 0;

        display: block;
        width: 100%;
        height: 94px;

        background-image: url('../assets/images/deepdives/dropdown_fallback.jpg');
        background-size:cover;
        @media #{$mobile}{
            background-image: url('../assets/images/deepdives/menu_fallback.jpg');
        }
    }
}

/**
 * Back Button
 */
.backbutton-deepdive-to-pov {

    display: none;

    width: 250px;
    text-align: center;
    z-index: 85;
    padding: 0 30px;
    margin: auto;

    opacity: 0;

    position:absolute;
    top:45px;
    height:94px;
    align-items:center;
    justify-content: center;
    transform:translateX(-50%);
    left:50%;
    pointer-events: auto;

    @media #{$mobile}{
        z-index: 300;
        transform:translateX(0px);
        left:0;
        width:auto;
    }
    a {
        display: flex;
        justify-content:center;
        align-items: center;
        width:100%;

        padding: 10px 24px 8px 24px;
        font-size: 14px;
        text-transform: uppercase;
        border-radius: 30px;
        background-color: #fff;

        @media #{$mobile}{
            background-color:transparent;
            border:2px solid rgba(255, 255, 255, 0.5);
            width:40px;
            height:40px;
            padding:9px;
            left:0px;
        }
        svg {
            width: 20px;
            // height:18px;
            left:-1px;
            position: relative;
            top:-1px;
            polygon {
                stroke:#101422;
                fill:rgba(255, 255, 255, 0.9);
            }

            // @media #{$tablet}{
                display:none;
            // }

            @media #{$mobile}{
                display:block;
            }

            transition:transform .5s $Power2EaseIn;
        }


        .desktop &:hover {
            svg {
                transition:transform .3s $Power2EaseOut;
                transform: rotate(90deg);
            }
        }

        span {
            white-space: nowrap;
            color: #101422;
            @media #{$mobile}{
                display:none;
            }
        }
    }

    &.show {
        display: flex;
    }
}

body.nav--open{
    .header__background{
        opacity: 0;
        transition: opacity .3s ease;
    }
}

.header__logo{
    position: relative;

    width: 160px;
    height: 100%;
    margin-left: 30px;

    background-image: url('../assets/images/logo_new.png');
    background-repeat: no-repeat;
    background-position: 0 center;
    background-size: 100%;
    pointer-events: auto;

    visibility: hidden;
    opacity: 0;

    .fallback.ie &{
        position:absolute;
        top:0;
        left:0;
    }

    .fr & {
        background-image: url('../assets/images/logo_new-fr.png');
    }

    @media #{$mobile}{
        position: absolute;
        z-index: 100;
        left: 50%;
        top:50%;
        height: 52px;
        margin-left: 0;

        transform: translate(-50%,-50%);
    }
}

.header__title{
    visibility: hidden;

    opacity: 0;
}

.header__toggle{
    position: absolute;
    z-index: 100;
    right: 0;
    top: 45px;
    align-items: center;
    justify-content: space-between;

    display: flex;
    width: 80px;
    height: 94px;
    margin-right: 30px;

    pointer-events: auto;
    visibility: hidden;

    .sliding &{
        // on home
        pointer-events: none;
    }

    @media #{$mobile}{
        position: absolute;
        z-index: 3001;
        top: 74px;

        width: 40px;
        height: 40px;

        border: 2px solid rgba(255,255,255,.5);
        border-radius: 50%;

    }

    &.opened{
        .header__label--opened{
            opacity: 1;
            transform: translateY(-2px);
        }

        .header__label--closed{
            opacity: 0;
            transform: translateY(-80px);
        }

        .burger__line--1{
            opacity: 0;
        }

        .burger__line--2{
            transform: translateY(-5px) rotate(45deg);
        }

        .burger__line--3{
            transform: translateY(5px) rotate(-45deg) ;
        }
    }
}

.header__label{
    // position: absolute;

    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;

    color: white;
    text-shadow: $distorsionShadow;

    transition: transform .5s ease, opacity .2s ease;
    transform: translateY(-80px);
    
    @media #{$mobile}{
        display: none;
    }
}

.header__label--closed{
    transform: translateY(-2px);
    position: absolute;
}

.header__label--opened{
    opacity: 0;
}

.header__burger{
    position: absolute;
    top: 50% ;
    right: 0;

    width: 20px;
    height: 11px;

    transform: translateY(calc(-50% - 3px));

    @media #{$mobile}{
        top: 50%;
        left: 50%;

        transform: translate(-50%,-50%);
    }

    .burger__line{
        position: absolute;
        left: 0;

        display: block;
        width: 100%;
        height: 2px;

        background-color: white;
        transition: opacity .4s ease, transform .4s ease;
        box-shadow: $distorsionShadow;

        &.burger__line--1{
            top: 5px;
        }

        &.burger__line--2{
            top: 10px;
        }
    }
}

.header__back--mobile{
    display:none;

    @media #{$mobile}{
        position: absolute;
        z-index: 100;
        top: 28px;
        left: 30px;
        align-items: center;
        justify-content: center;

        display: flex;
        width: 40px;
        height: 40px;

        border: 2px solid rgba(255,255,255,.5);
        border-radius: 50%;
        pointer-events: auto;

        display: none;
    }

    svg{
        position: relative;
        top: -1px;
        left: -1px;
    }
}
