.view__generic{
	.main__content{
		width: 100%;
		max-width: 1140px;
		margin: auto;

		padding-top:95px;

		@media #{$mobile}{
			width: calc(100% - 40px);
			padding: 100px;
		}
		
		@media #{$cell}{
			width: calc(100% - 40px);
			padding: 95px 0px 20px 0px;
		}
	}

	h1{
		font-family: $avantGarde;
		font-size: 13px;
		font-weight: 700;
		text-transform: uppercase;
		color: #fff;

		text-align: center;

		@media #{$mobile}{
			margin-top: 100px;
		}
	}

	.content{
		width: 80%;
		margin: 200px auto 0;
		padding-bottom: 100px;

		@media #{$mobile}{
			width: 100%;
			margin: 50px auto 0px;
			padding-bottom: 140px;
		}
	}

	p{
		margin-bottom: 40px;

		font-family: $avantGarde;
		font-size: 20px;
		font-weight: 300;
		line-height: 1.6em;
		color: #fff;

		@media #{$mobile}{
			margin-bottom: 30px;

			font-size: 15px;
		}
	}
}
