.middledive__slider--circles{
	z-index: 200;

	.slider__container{
		align-items: center;

		display: flex;
		width: 2000px;
		height: 300px;
		padding: 0 80px;

		@media #{$mobile}{
			display: block;
		}
	}

	.item{
		&:before{
			position: absolute;
			bottom: -60px;

			width: 100%;
			height: 70px;

			content: '';
		}
	}

	.middledive__slider--line{
		position: absolute;
		bottom: 0;

		display: block;
		width: calc(100% - 10px);
		height: 300px;

		circle{
			position: absolute;
			top: 50%;

			transition: transform .4s ease;
		}
	}

	.years{
		position: absolute;
		top: calc(50% + 105px);
		left: 50%;

		font-size: 20px;

		color: #fff;
		transform: translate(-50%,-50%);
		white-space: nowrap;
		pointer-events: none;
	}

	.circles{
		position: relative;
		bottom: 0;
		z-index: 200;

		display: block;
		width: 135px;
		height: 150px;
		margin-right: 100px;

		.circle{
			position: absolute;
			bottom: 0;

			display: block;
			width: 135px;
			height: 135px;

			border: 2px solid #fff;
			border-radius: 50%;
			background: rgba(0,0,0,.3);
			pointer-events: none;
		}

		.circle-bg{
			position: absolute;
			bottom: 0;

			display: block;
			width: 135px;
			height: 135px;

			border: 2px solid #fff;
			border-radius: 50%;
			pointer-events: none;
		}

		.mask{
			position: absolute;
			bottom: 2px;
			left: 50%;

			display: block;
			overflow: hidden;
			width: 130px;
			height: 232px;

			border-bottom-right-radius: calc(135px / 2);
			border-bottom-left-radius: calc(135px / 2);
			transform: translateX(-50%);
			pointer-events: none;
		}

		&.active{
			img{
				transform: translate(-50%,-40%);
			}
		}

		svg{
			position: absolute;
			z-index: 5;
			left: 0;

			pointer-events: none;

			fill: transparent;
		}

		svg path{
			transform-origin: bottom;
			pointer-events: none;
		}

		img{
			position: absolute;
			z-index: 2;
			top: 50%;
			left: 50%;

			max-width: none;

			transition: transform .4s ease;
			transform: translate(-50%,-30%);
			pointer-events: none;

			.safari &{
				transition: none;
				transform: translate(-50%,calc(-50% + 50px));
			}
		}
	}

	// Mobile
	@media #{$mobile}{
		.slider__container{
			width: 2000px;
			height: 300px;
			padding: 0 0px;
		}
		.circles{
			position: relative;
			left: auto !important;

			display: block;
			width: 100%;
			height: auto;
			padding: 15px 25px;

			&:before{
				position: absolute;
				bottom: 0;
				left: 0px;

				width: 100%;
				height: 2px;

				opacity: .5;
				background-color: #fff;
				content: '';
			}
		}

		.circles p{
			font-size: 22px;

			color: #fff;
		}

		img,
		svg,
		span,
		.circles .circle,
		.circles .circle-bg,
		.mask,
		.middledive__slider--line{
			display: none;
		}
	}
}
