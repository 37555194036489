.middledive__slider--images{
	position: absolute;
	z-index: 2;
	bottom: 0;
	left: 0;

	width: 100%;

	font-size: 0;

	opacity: .3;
	transform: translateY(130px);

	@media #{$mobile}{
		padding-bottom:60px;
	}

	.swiper-slide{
		width:250px;
		height:auto;
	}

	&.middledive__slider .slider__container{
		height: 180px;
        padding: 0 30px;
        
        @media #{$mobile}{
            height:auto;
        }
	}

	.images{
		position: relative;
		bottom: 20px;

		display: inline-block;

		transition: transform .3s ease;

		&.active{
			transform: scale(1.1);

			svg.translateItem{
				transform: translateX(25px) scale(1);
			}
		}

		svg{
			position: absolute;
			z-index: 1;
			left: 0;

			width: 200px;
			height: 136px;

			transition: transform .3s ease;
			pointer-events: none;
		}

		svg path{
			transition: transform .3s ease;
			transform: scaleX(.9);
			transform-origin: bottom;
			pointer-events: auto;
		}

		img{
			position: relative;
			z-index: 2;

			width: 200px;
			max-width: none;
			height: 136px;
			margin-right: 30px;

			pointer-events: none;
		}
	}

	@media #{$mobile}{
		.images{
			position: relative;
			bottom: 0px;
			left: auto !important;

			display: block;
			width: 100%;
			padding: 15px 25px;

			&:before{
				position: absolute;
				bottom: 0;
				left: 0px;

				width: 100%;
				height: 2px;

				opacity: .5;
				background-color: #fff;
				content: '';
			}
		}

		.images p{
			font-size: 22px;
			color: #fff;
		}

		img,
		svg{
			display: none;
		}
	}
}
