/* ============================*/
/* ==[ Floating text ]== */
/*=============================*/
.floating-text{
	width: 100vw;
	height: 290px;

	transform: translateX(calc((100vw - #{$container-content-width} + #{$container-content-padding} ) / -2));
	position:relative;

	@media #{$mobile}{
		display: none;
	}

	h2{
		position: absolute;

		margin-bottom: 10px;
		padding-top:40px;

		font-size: 250px;
		font-weight: 500;
		line-height: 1em;

		top:-40px;
		
		white-space: nowrap;

		body:not(.ie) &{
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
		

		@media #{$tablet}{
			font-size: 180px;
		}

		@media #{$mobile}{
			font-size: 100px;
		}
	}
}
