/**
 *
 */

.nav {
    position: fixed;
    z-index: 90;
    top: 0;
    right: -10px; // hide box shadow
    align-items: center;

    display: flex;
    width: 40vw;
    max-width: 600px;
    height: 100%;
    padding-left: 100px;

    color: #fff;

    //transition: transform .5s $Power4EaseInOut;
    pointer-events: none;

    @media #{$smallDesktop}{
        padding-left: 7vw;
    }

    @media #{$mobile}{
        width: 60vw;
        max-width: none;
        padding-left: 55px;
        pointer-events: none;
        z-index: 3000;
    }

    @media #{$cell}{
        width: 80vw;
        padding-left: 25px;
    }

    .nav--open & {
        pointer-events: auto;
    }

}

.nav__background{
    position: absolute;
    z-index: 5;
    top: 45px;
    right: 0;

    width: 40vw;
    max-width: 600px;
    height: calc(100vh - 45px);

    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    pointer-events: none;

    .fallback & {
        display: none
    }
}

/**
 * Shadow 
 */
.nav__shadow {

    position: absolute;

    right:-70px;
    top:45px;
    height: calc(100vh - 45px);
    width:70px;

    //background-image: linear-gradient(-90deg, #000 75%, transparent 25%);
    background-image: linear-gradient(-90deg, #000 50%, transparent)

    //@include debug;
}

.nav__background--fallback{
    display: none;

    .fallback &{
        position: absolute;
        top: 0;
        left: 0;

        display: block;
        width: 100%;
        height: 100%;

        z-index: 5;

        background-image: url('../assets/images/deepdives/menu_fallback.jpg');

        transform: translateX(100%);
        transition: transform .3s ease-out;

        @media #{$mobile}{
            //width: 100vw;
            max-width: none;
        }

        &:after {
            content: '';

            @include absoluteBox;
            z-index: 5;

            background: rgba(0, 0, 0, 0.25);
        }
    }

    .fallback.nav--open & {
        transform: translateX(0%);
    }
}

.nav__item{
    position: relative;

    display: block;

    outline: 0;
    opacity: 0;

    .desktop &:hover{

        .nav__link {
            text-decoration:none;
            &:after{
                opacity: 0.2;
            }
        }
    }

    .nav__link:after{           
        content: '';

        position: absolute;
        top: 50%;
        left: 40%;
        z-index: 1;

        width: 150%;
        height: 500%;

        opacity: 0;
        background: url('/assets/images/common/bg-nav-item.png') no-repeat center center;
        background-size: 100%;
        transition: opacity 0.5s ease;
        transform: translate3d(-50%,-50%, 0);
        pointer-events: none;
    }

    .nav__link:focus:after {
        opacity: 1;
    }
}

.nav__item--small{
    margin: 14px 0;

    @media #{$mobile}{
        a span{
            font-size:18px;
        }
    }
}

.nav__list{
    position: relative;
    z-index: 10;

    @media #{$mobile}{
        margin-top: 7vh;
    }
}

.nav__link{
    padding: 12px 0;

    color: white;

    outline: 0;

    .nav__item--big &{
        font-size: 45px;
    
        @media screen and ( max-width: 1280px ) {
            padding: 14px 0;
            font-size: 40px;
        }

        @media screen and ( min-width: 1281px ) {
            padding: 16px 0;
            font-size: 50px;
        }

        @media #{$tablet}{
            font-size: 38px;
        }
    }

    .nav__item--small &{
        font-size: 20px;
        left: 2px;
        position: relative;

        @media #{$mobile}{
            left:3px;
        }

        &.nav__link--glitch:after{
            display:none;
        }
    }
}

.nav__target {
    position: absolute;
    right: 0;
    z-index: 1;

    width: calc(100vw + 10px);
    height: 100vh;
    
    background-color: rgba(#000, .6);

    display: none;

    transform-origin: top right;
    transform: skew(15deg, 0) scale(0,1);
}

.nav__link--glitch{
    display: block;
    
    transition: transform 30s ease;

    span {
        position: relative;
        z-index: 2;

        display: block;

        color: #fff;

        transition: transform .5s ease, color .3s $Power4EaseOut;
        pointer-events: none;
        transform: translateZ(0);
        @media #{$mobile}{
            font-size:30px;
        }
        @media #{$cell}{
            font-size:24px;
        }
    }

    &.effects {
        span {
            outline: 90px solid transparent !important;
            text-shadow: $distorsionShadow;
        }
    }

    &:before{
        position: absolute;
        z-index: 1;

        color: rgba($c-primary, 0);

        content: attr(data-text);
        transition: transform .3s $Power4EaseOut, color 0.3s $Power4EaseOut;
        transform: translate3d(0px,0px,0px);
        pointer-events: none;
    }

    .desktop &:hover{
        span{
            transform: translateX(2px);
            // filter: url(#filter1);
        }

        &:before{
            transform: translate3d(7px,0px,0px);
            color: rgba($c-primary, 1);
        }
    }

    .desktop.safari &:hover{
        span{
            transform: translateZ(0px);
            // filter: url(#filter1);
        }
    }   
}

.nav__item:nth-child(1) .nav__link--glitch {
    &.active {
        span {
            transform: translateX(10px);
            // filter: url(#filter1);
        }
    }
}

.nav__item:nth-child(2) .nav__link--glitch {
    &.active {
        span {
            transform: translateX(10px);
            // filter: url(#filter2);
        }
    }
}

.nav__item:nth-child(3) .nav__link--glitch {
    &.active {
        span {
            transform: translateX(10px);
            // filter: url(#filter3);
        }
    }
}

.nav__item:nth-child(4) .nav__link--glitch {
    &.active {
        span {
            transform: translateX(10px);
            //filter: url(#filter4);
        }
    }
}

.nav--item-hover .nav__link--glitch, .nav--item-current .nav__link--glitch {
    span {
        color: rgba(#fff, .3);
    }
}

.nav--item-hover .nav__item:hover .nav__link--glitch {
    span {
        color: rgba(#fff, 1);
    }
}

.nav__link--glitch.nav__link--current {

    .nav--item-current & {
        span {
            color: rgba(#fff, 1);
        }
    }

    .nav--item-hover & {
        span {
            color: rgba(#fff, .3);
        }
    }
}
