.middledive{
	position: absolute;
	z-index: 80;
	top: 0;

	display: block;
	// border-bottom-left-radius:20px;
	// border-bottom-right-radius:20px;
	display: none;
	overflow: hidden;
	width: 100%;
	height: calc(400px + 94px);
	padding-top: 94px;

	opacity: 0;
	background-color: #000;
	user-select: none;
	transform: translateY(-400px);
	pointer-events: auto;

	

	&.middledive__slider--images .swiper-slider{
		width:250px;
	}

	.with-onf-banner &{
		top: 45px;		
	}

	.bg-gradient{
		position:absolute;
		bottom:0;
		right:0;
		z-index: 5;
		opacity:0;
		transition:opacity .3s ease;
		pointer-events:none;
	}

	&.opened .bg-gradient{
		opacity:1;
	}

	@media #{$mobile}{
		height: auto;

		transform: none;

		body:not(.mobile) &{
			margin-bottom: 50px;
		}

		body.nav--open &{
			pointer-events: none;
		}
	}

	.middledive__bg{
		position: absolute;
		left: 0;

		width: 100vw;
		height: 100%;

		opacity: .5;
		background-color: #000;
		transition: opacity .2s ease;
		pointer-events: none;
		z-index: 2;

		@media #{$mobile}{
			display: none;
		}
	}

	&.opened .middledive__bg{
		opacity: 0;
	}

	.subtitle{
		display: block;
		margin-bottom: 5px;

		font-size: 20px;

		color: #fff;

		@media #{$mobile}{
			font-size: 16px;
		}
	}

	&.active{
		display: block;
	}

	.background-gradient{
		position: absolute;
		z-index: 1;
		top: 0;

		width: 100%;
		height: calc(400px + 94px);

		pointer-events: none;

		@media #{$mobile}{
			height: 150vh;
		}

		.fallback &{
			display: none;
		}
	}

	.background-gradient--fallback{
		display: none;

		.fallback &{
			position: absolute;
			top: 0;

			display: block;
			width: 100%;
			height: 100%;

			background-image: url('../assets/images/deepdives/dropdown_fallback.jpg');
			background-size:cover;
			@media #{$mobile}{
				background-image: url('../assets/images/deepdives/menu_fallback.jpg');
			}
		}
	}

	.text-container{
		position: absolute;
		z-index: 2;
		left: 50%;

		width: 100%;
		max-width: 730px;

		opacity: 1;
		transform: translate(-50%,0px);
        text-align: center;

		@media #{$mobile}{
			position: relative;
			left: auto;
            margin:auto;
			margin-top: 20px;

			transform: none;
		}

		h3,
		p{
			width: 100%;
			max-width: 730px;
			margin: auto;

			color: #fff;
		}

		h3{
			margin-bottom: 5px;

			font-size: 45px;
			font-weight: 500;

			@media #{$mobile}{
				max-width: 60%;
				margin: auto;
				margin-top: 10px;
				margin-bottom: 15px;

				font-size: 22px;
			}
		}

		p{
			width: 100%;
			max-width: 640px;

			font-size: 16px;
			font-weight: 300;
			line-height: 1.9em;

			@media #{$mobile}{
				max-width: 90%;

				font-size: 13px;
			}
		}
	}

	.name__container{
		position: absolute;
		z-index: 2;
		top: 0;

		width: 100%;
		margin: auto;

		opacity: 0;
		transform: translateY(20px);
		text-align: center;
		
		

		h3,
		span{
			max-width: 620px;
			margin: auto;

			font-weight: 500;

			color: #fff;
		}

		h3{
			margin-bottom: 5px;

			font-size: 45px;
		}

		span{
			display: block;
			margin-bottom: 5px;

			font-size: 14px;
			text-transform: uppercase;
		}
	}

	.middledive__title{
		position: absolute;
		z-index: 2;
		bottom: 15px;

		width: 100%;

		h4{
			display: block;

			font-size: 14px;
			font-weight: 400;
			text-transform: uppercase;

			color: #fff;
			text-align: center;
			pointer-events: none;
		}

		svg{
			margin-left: 10px;
		}
	}

	.floating-names{
		position: absolute;
		z-index: 2;
		
		span{
			display: block;

			font-size: 320px;
			line-height: 1.5em;
			
			body:not(.ie) &{
				background: repeating-linear-gradient(transparent, transparent 2px, $c-pink 4px, $c-pink 4px);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;	
			}
			
			
			white-space: nowrap;
			transform: translateZ(0);
		}
	}

	.middledive__instruction{
		position: absolute;
		z-index: 200;
		right: 25px;
		bottom: 5px;

		display: block;
		cursor: url("../assets/images/cursor/pointer.png"), pointer;
		opacity: 0;
		transform: translateX(120px);

		.desktop &:hover{
			svg{
				transform:scale(1.1)  translate(2px) rotate(0deg) ;
			}
			&.-left{
				svg{
					transform:scale(1.1)  translate(2px) rotate(180deg) ;
				}	
			}
		}

		&.-left{
			left:25px;
			right:auto;
			transform: translateX(-120px);
			svg{
				transform: translate(2px) rotate(180deg) ;
			}
		}

		&.swiper-button-disabled{
			svg{
				opacity:0;
			}
		}

		svg{
			transform: translate(2px) rotate(0deg) ;
			width:61px;
			height:42px;
			cursor: url("../assets/images/cursor/pointer.png"), pointer;
			transition: transform .4s ease,opacity .4s ease;
			will-change:transform;
			opacity:1;
			
			path{
				fill:#fff;
			}
		}

		p{
			font-size: 13px;
			font-weight: 400;
			text-transform: uppercase;

			color: #fff;
			text-align: left;
			pointer-events: none;
		}
	}

	.middledive__slider{
		position: absolute;
		z-index: 3;
		bottom: 0;
		left: 0;

		width: 100%;

		font-size: 0;

		opacity: .3;
		transform: translateY(130px);
		overflow: visible;

		

		@media #{$mobile}{
			position: relative;
			top: auto;
			bottom: auto;

			opacity: 1;
			transform: translateY(0px);
		}

		.slider__container{
			position: relative;

			width: 2000px;
			// box-sizing: content-box;
			max-width: none;
			// height: 300px;

			cursor: pointer;

			@media #{$mobile}{
				width: auto;
				height: auto;
				cursor: default;
			}
		}
	}

	.middledive__close--mobile{
		display: none;

		@media #{$mobile}{
			position: relative;

			display: block;
			margin: 30px auto 25px;

			&:before,
			&:after{
				position: absolute;
				z-index: 10;
				top: 50%;

				display: block;
				width: 25%;
				height: 2px;

				opacity: .5;
				background-color: #fff;
				content: '';
				transform: translateY(-50%);
			}

			&:before{
				left: 0;
			}

			&:after{
				right: 0;
			}

			a{
				display: block;

				font-size: 0;

				text-align: center;
			}

			svg{
				position: relative;
				z-index: 10;

				display: inline-block;
				width: 110px;
			}
		}
	}

	@media #{$mobile}{
		.item{
			&:last-of-type{
				margin-bottom: 60px;
			}
		}
	}
}

.wrapper__mobile{
	@media #{$mobile}{
		position: fixed;
		z-index: 400;

		display: block;
		overflow: scroll;
		width: 100%;
		height: 100vh;

		pointer-events: none;
	}
}
