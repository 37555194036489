/* ============================*/
/* ==[ Full gradiant Block ]== */
/*=============================*/

.full-gradiant{
	position: relative;
	left: calc((100vw - #{$container-content-width} + #{$container-content-padding} ) / -2);

	overflow: hidden;
	width: 100vw;
    height: 800px;
    // MarginChange
	// margin-bottom: 100px;
    margin-bottom: 80px;

	@media screen and (max-width: $container-content-width){
		left: -40px;

		max-width: 100vw;
	}

	@media #{$mobile}{
		flex-direction: column;
		height: auto;
		margin-bottom: 50px;
	}

	@media #{$mobile}{
		left: -20px;
	}


	.full-gradiant--desktop{
		display: block;

		@media #{$mobile}{
			display: none;
		}
	}

	.full-gradiant--mobile{
		display: none;

		@media #{$mobile}{
			position: relative;

			display: block;
			overflow: hidden;
			width: 100%;
			height: 115vw;

			img{
				width: 100%;
				height: 100%;

				object-fit: cover;
                object-position: top right;
			}
		}
	}


	.full-gradiant__wrapper{
		position: relative;
		z-index: 100;
		top: 170px;

		max-width: $container-content-width;
		margin: auto;
		lost-utility: clearfix;

		.full-gradiant__col{
            // float: right;
            left: 27%;
			position: relative;
			@media #{$mobile}{
				left:0%;
			}
		}

		@media #{$mobile}{
			top: 0;

			float: none;
			width: 100%;
			margin-top: 50px;
			padding: 0 20px;
		}
	}

	.full-gradiant__front{
		position: absolute;
		z-index: 11;
		top: 0;
		left: 0;

		display: block;
		width: 100%;
		height: 100%;

		object-fit: cover;
        object-position: top center;
        
        @media #{$mobile}{
            display: none;
        }
        
	}

	.full-gradiant__quote{
		width: 90%;
		max-width: 550px;

		@include bigQuote();

		p{
            color: #fff;
            margin-bottom:20px;
		}

		@media #{$mobile}{
			width: 100%;
			max-width: none;
			padding-top: 15px;

			p{
				color: inherit !important;
			}
		}

		@media #{$notMobile}{
			.line{
				background-color: #fff !important;
			}
		}
	}

	.full-gradiant__bg{
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		object-fit: cover;
        object-position: top center;
        
        
	}

	.floating-text{
		position: relative;
		z-index: 5;
		top: 170px;
		left: 0;

		width: 100vw;
		height: 250px;

		transform: none;

		h2{
			padding-top: 40px;

			color: #fff;

			background: none;
			-webkit-text-fill-color: #fff;
		}
	}
}
