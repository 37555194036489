/*::-moz-selection{background: #fcd700; color: #fff; text-shadow: none;}
::selection {background: #fcd700; color: #fff; text-shadow: none;}*/

/*a:link {-webkit-tap-highlight-color: #fcd700;}*/

html {
    width: 100vw;
    overflow: hidden;
}

.flip-mobile-overlay{
    display:none;
    @media only screen and (orientation: landscape){
        .mobile &{
            position:absolute;
            z-index: 99999;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background-image:url('/assets/images/mobile_rotate_background.jpg');
            background-size:cover;
            display:flex;
            justify-content: center;
            align-items:center;
    
            p{
                font-size:28px;
                font-family: $avantGarde;
                color:white;
                margin-top:20px;
            }
    
            .wrapper{
                display:flex;
                align-items:center;
                justify-content: center;
                flex-direction: column;
                padding:20px;
                text-align: center;

                img{
                    width: 60px;
                }
            }
        }
    }
}

body {
    width: 100vw;

    font-family: $avantGarde;

    background-color: black;
    overflow: hidden;

    cursor: url('../assets/images/cursor/default.png'), default;

    h1,h2,h3,h4,h5,h6,p,a,span {
        @include smooth;
    }

    &.cursor--grab {
        cursor: url('../assets/images/cursor/grab.png'), grab;
    }

    &.cursor--grabbing {
        cursor: url('../assets/images/cursor/grabbing.png'), grabbing;
    }

    &.cursor--loading {
        cursor: none;
    }

    &.cursor--hidden {
        cursor: none;
    }
}

button, input {
    font-family: $avantGarde;
}

#root {
    position: relative;
    z-index: 75;
    
    // display: none;
    width: 100vw;
    height: 100%;
    
    overflow-y: scroll;
    overflow-x: hidden;

    &:after {
        // content: '';

        @include absoluteBox;
        z-index: 99999;

        // background: url('../assets/images/noise.gif') repeat;

        opacity: 0.08;

        pointer-events: none;
    }
}

#canvas {
    position: fixed;
    top: 45px;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: 50;
}

button {
    background-color: transparent;
    outline: 0;
}

.svg-filters{
    position:absolute;
    top: -100vh;
}

.main {
    position: relative;
    min-height: 100vh;

    @media #{$tablet}{
        min-height: 100%;
    }

    @media #{$mobile}{
        height:100%;
        min-height:0;
    }


    &:after {
        content: '';

        @include absoluteBox;
        z-index: 999;

        background-color: rgba(0, 0, 0, 0.6);
        opacity: 0;
        will-change:opacity;
        transition: opacity 0.5s $Power4EaseInOut;
        // transition: background-color 0.5s $Power4EaseInOut;
        pointer-events: none;

        transform: translateZ(0);
    }
}

.nav--open .main:after {
    opacity: 1;
}

/**
 *
 */

.canvas-overlay {
    position: absolute;
    top: 0%;
    left: 0;

    display: none;
    width: 100vw;
    height: 100vh;

    z-index: 10000;
    // opacity: 0.01;

    pointer-events: none;

    @media #{$tablet}{
        height:100%;
    }
}

a, button {
    cursor: url('../assets/images/cursor/pointer.png'), pointer;
}

.cursor {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;
    
    width: 33px;
    height: 33px;

    pointer-events: none;
    opacity: 0;

    .fallback & {
        display: none;
    }

    .cursor--loading & {
        opacity: 1;
    }
}

$scan-width: 2px;
$scan-color: rgba(#000, .03);

// @keyframes scanlines {
//     0% {
//         background-position: 0 50%;
//         // bottom: 0%; // to have a continuous scanline move, use this line (here in 0% step) instead of transform and write, in &:before, { position: absolute; bottom: 100%; }
//     }
// }

body:after {
    // content: '';

    @include absoluteBox;
    z-index: 999999;

    background: linear-gradient(to bottom,transparent 50%,$scan-color 51%);
    background-size: 100% $scan-width*2;
    // animation: scanlines 5s steps(60) infinite;

    pointer-events: none;
}

// header ONF
.fallback #volumeBt {
    display: none;
}