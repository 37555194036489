.loader {
    @include absoluteBox;
    z-index: 1000;
    align-items: center;
    justify-content: center;

    display: flex;
    flex-direction: column;

    .home__button {
        right: auto;
        transform:translateX(-50%);
        left:50%;
        bottom: 6%;

        &.pulse:before{
            animation: pulse 2s ease infinite;
        }
    }
    .home__label{
        white-space: nowrap;
    }
}

@keyframes pulse {
    from { transform:  scale(1); }
    50% { transform:  scale(0.85); }
    to { transform:  scale(1); }
}

.loader__bkg {
    @include absoluteBox;

    background-color: black;
}

.loader__title {
    position: relative;

    display: block;
    width: 75vw;

    color: white;
    font-size: 15px;
    text-transform: uppercase;

    background-size: contain;

    @media #{$mobile}{
		width: 75vw;
	}

    .fr & {
        // background: url('/assets/images/logo-big-fr.png') center center no-repeat; 
    }

    svg {
        width: 50vw;
        height: auto;
        margin-left: 12.5vw;

        fill: white;

        @media #{$mobile}{
            width: 100%;
            margin-left:0vw;
        }
    }
}

.loader__canvas {
    position: absolute;
    left: 0;

    .en & {
        top: 0;
    }

    .fr & {
        top: 40%;
    }
}

.loader__stroke {
    transform: translateY(100px) scale(1.3);
}

@keyframes dot {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.loader__image {
    position: absolute;
    width: 75vw;
    height: 43vw;

    .ie &{
        transform: translate(-50%,-50%);
        left: 50%;
        top: 50%;
    }
    
    @media #{$tablet}{
        transform: translate(-50%,-50%);
        left: 50%;
        top: 50%;
    }

    @media #{$mobile}{
        width: 100vw;
        height: 57vw;
    }

    // background: url('/assets/images/common/splash.png') center center no-repeat;
    
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;

    opacity: 0;
}

.loader__text {
    position: absolute;
    bottom: 15%;
    
    display: flex;
    width: 100%;
    padding: 20px 30px;
    
    text-align: center;
    justify-content: center;

    opacity: 0.5;
    
    span {
        color: white;
        font-size: 14px;
        text-transform: uppercase;

        text-shadow: $distorsionShadow;
    }

    .loading__dots {
        display: flex;
    }

    .loading__dot {
        animation-name: dot;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;

        text-shadow: $distorsionShadow;
    }

    .loading__dot:nth-child(1) {
       
    }

    .loading__dot:nth-child(2) {
        animation-delay: 0.2s;
    }

    .loading__dot:nth-child(3) {
        animation-delay: 0.4s;
    }
}

.path--supreme {
    display: none;
}

.path--l {
    opacity: 0;
}

.path--a {
    opacity: 0;
}

.path--w {
    opacity: 0;
}

.path--underline-left {
    opacity: 0;
}

.path--underline-right {
    opacity: 0;
}

.path--o {
    opacity: 0;
}

.path--i-middle, .path--i-top, .path--i-bottom {
    opacity: 0;
}
 
.path--supreme-stroke {
    fill: transparent;
    stroke-width: 1px;
    stroke: white;
}

.loader__featured {
    position: absolute;
    top: calc(75% - 30px);
    left:50%;
    transform:translateX(-50%);
    width:80%;
    text-align: center;

    @media #{$mobile}{
        width:90%;
        text-align: center;
        padding:0 20px;
        // top: calc(68% - 30px);
        top: calc(64% - 30px)
    }

    .featured__stars {
        margin-bottom: 10px;

        color: white;
        font-size: 26px;
        letter-spacing: 0.03em;
        text-align: center;
        opacity: 0;

        @media #{$cell}{
            font-size:20px;
            
        }
    }

    .featured__direction {
        color: rgba(white, 0.6);
        font-size: 14px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        text-align: center;

        opacity: 0;
    }
}

.loader__progress {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1001;

    width: 100vw;
    height: 6px;
}

.loader__fill {
    @include absoluteBox;

    background: linear-gradient(to right, #e72c9f 0%,#d32424 100%);
    transition: transform 0.2s $Power4EaseInOut;
    transform-origin: 0 50%;
    transform: scaleX(0);
}