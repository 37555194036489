/* ========================*/
/* ==[ Typeographie ]== */
/*========================*/

// Default text color
p,span{
	color:$c-text;
}

@mixin regularText(){
	color:$c-text;
	font-weight:300;
	font-size:16px;
	line-height:2em;
	@media #{$mobile}{
		font-size:13px;
	}
}

@mixin videoQuote(){
	font-size:25px;
	font-weight: 500;
	// color:$c-secondary;
	line-height:1.4em;
}

@mixin subTitle(){
	font-size:20px;
	font-weight: 500;
	// color:$c-secondary;
	line-height:1.4em;	
	@media #{$mobile}{
		font-size:calc(30px / 2);
	}
}

@mixin bigQuote(){
	position: relative;
	font-size: 35px;
	color: inherit;
	font-weight: 500;
	line-height: 1.3em;
    padding-top: 25px;
    
    .infobox{
        pointer-events: none;
        line-height: 1em;
    }
    .infobox span{
        font-size:35px;
    }
	
	@media #{$tablet}{
        font-size:28px;
        .infobox span{
            font-size:28px;
        }
	}

	@media #{$mobile}{
		font-size:calc(35px / 2);
        padding-top:15px;
        .infobox span{
            font-size:calc(35px / 2);
        }
	}

	.line{
		content:'';
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:5px;
	}
}
