/* ========================*/
/* ==[ DeepDive ]== */
/*========================*/
/* [GEN] ==== General
/* [TRI] ==== Triangle
/* ========================*/

/* -----------------------*/
/* [GEN] ==== General
/*------------------------*/



.deepdive__slide{
	position: relative;
	z-index: 40;

	width: 100%;
	height: 770px;

    background-color: $c-white;

    .plyr--full-ui input[type=range]{
        height:5px;
    }

    .plyr__captions span{
        color:white;
    }
    
    &:nth-of-type(1){
        // margin-top: -20px;
        .triangle__text{
            top: 40px;
        }
    }    

	@media #{$cell}{
		height: 180vw;
	}

	@for $i from 1 to 5{
		&:nth-of-type(#{$i}){
			z-index: #{$i};
		}
	}

	&.view__deepdive--closed{
		.main__content{
			position: absolute;

			width: 100%;
			max-width: 1140px;

			@media #{$mobile}{
				width: calc(100% - 40px);
			}

			@media #{$cell}{
				width: calc(100% - 40px);
			}
		}
	}
}

.deepdive__intro{
	overflow: hidden;
	max-height: 1150px;
}

.deepdive__container{
	display: block;
	overflow: hidden;
	// margin-top: 180px;
    // margin-top: 150px;
    margin-top: 98px;
	padding-bottom: 94px;

	font-size: 0;

	background-color: #fff;
    opacity: 0;
    @media #{$mobile}{
        margin-top: 94px;
    }
    
    section:first-child{
        &.deepdive__slide{
			top:90px;
			padding-bottom:80px;
        }
    }
    
    .navigation{
        height: 100px;
        width: 100%;
        background-image: url('/assets/images/fallback/bkg-constitution-small.jpg');
        z-index: 61;
        position:relative;
        background-size:cover;
        // margin-top: -20px;

        .wrapper{
            height:100%;
            width: 100%;
            max-width: 1220px;
            margin: auto;
            padding: 0 40px;
            display: flex;
            justify-content: space-between;
            align-items:center;
            // top: 20px;
			position: relative;
			@media #{$mobile}{
				padding:0 20px;
			}

        }

        .next,.prev{
            font-size:14px;
            text-transform: uppercase;
            opacity: 1;
			z-index: 32123;
			@media #{$cell}{
				font-size:12px;
			}
            span{
                color:#fff;
            }
        }

        .prev{
            &:hover{
                img{
                    transform:rotate(180deg) translateX(10px);        
                }
            }
        }

        .next{
            &:hover{
                img{
                    transform: translateX(10px);        
                }
            }
        }

        .prev img{
            transform:rotate(180deg);
            right:10px;
            position:relative;
            top:1px;
            transition:transform .3s ease;
        }

        .next img{
            left:10px;
            position: relative;
            top:1px;
            transition:transform .3s ease;
        }

        .btn{
            cursor: url('/assets/images/cursor/pointer.png'), pointer;
            display: inline-flex; 
            padding: 10px 24px 8px 24px;
            font-size: 14px;
            text-transform: uppercase;
            color: #349ab4;
            border-radius: 30px;
			background-color: #fff;
			
			@media #{$mobile}{
				padding:10px 18px 8px 18px;
			}

			@media #{$cell}{
				padding:10px 14px 8px 14px;
			}

            &.center{
                position: absolute;
                top: 50%;
                left: 50%;
                transform:translate(-50%,-50%);
                transition: background-color .4s ease;
                span{
                    transition: color .4s ease;
                }
                &:hover{
                    background-color:#162441; 
                    span{
                        color:#fff;
                    }
                }
            }
            span{
                color: #162441;
                font-size: 14px;
                position:relative;
                top:-1px;
                transform:translateZ(0);
                display: block;
            }
        }
    }
}

.content__wrapper{
	display: block;

	&.content__wrapper--hidden{
		display: none;
	}
}

.deepdive__wrapper{
	width: 100%;
	max-width: $container-content-width;
	margin: auto;
	padding: 0 40px 0px;

	@media #{$mobile}{
		padding: 0 20px 10px;
	}
}

.deepdive__separator{
	position: absolute;
	z-index: 30;
	top: 0;

	overflow: hidden;
	width: 100%;
	height: 170px;
	margin-top: -150px;

	opacity: 0;
	border-bottom-right-radius: 20px;
	border-bottom-left-radius: 20px;
    // box-shadow: 3px 18px 30px 1px rgba(0, 0, 0, 0.25);
    box-shadow: 3px 28px 30px -11px rgba(0, 0, 0, 0.25);
    

	&:before{
		position: absolute;
		z-index: 10;
		bottom: 0;
		left: 0;

		width: 100%;
		height: 170px;

		background: linear-gradient(to bottom, rgba(255,255,255,.0) 40%, rgba(255,255,255,1) 90%);
		content: '';
	}
}

.deepdive__readmore{
	position: absolute;
	z-index: 11;
	bottom: 0;
	left: 0;

	width: 100%;

	span{
		margin-bottom: 5px;

		font-size: $fs-13;
		text-transform: uppercase;
	}

	span,
	.readmore__arrow{
		position: relative;
		z-index: 11;

		color: #fff;

		pointer-events: none;
		*{
			fill: #fff;
		}
	}

	.readmore__wrapper{
		position: absolute;
		top: -100px;
		left: 50%;
		align-items: center;
		justify-content: center;

		display: flex;
		flex-direction: column;
		width: 200px;
		height: 100px;
		padding-top: 35px;

		cursor: pointer;
		transform: translateX(-50%);
	}

	.readmore__triangle{
		position: absolute;
		bottom: 0;
		left: 50%;

		transform: translateX(-50%);
	}
}

.gradient__svg{
	position: absolute;
}

/* -----------------------*/
/* [TRI] ==== Triangle
/*------------------------*/
.triangle__wrapper{
	position: absolute;
	left: 0px;

	overflow: hidden;
	width: 100vw;
    height: 950px;
    
    @media #{$tablet}{
        pointer-events: none;
    }

	.triangle__mask--container{
		position: absolute;
		z-index: 3;
		top: -400px;
		right: -5%;

		overflow: hidden;
		// width: 1300px;
        // height: 1300px;
        // width: 800px;
        // height: 800px;
        width: 710px;
        height: 838px;

		background-color: transparent;
		transform: rotate(45deg);

		.triangle_img--mobile{
			display: none;

			@media #{$mobile}{
				display: block;
			}
		}

		.triangle_img--desktop{
			display: block;

			@media #{$mobile}{
				display: none;
			}
		}

		@media #{$bigDesktop}{
			right: 0%;
		}

		@media #{$hugeDesktop}{
			right: 0;
		}

		@media #{$smallDesktop}{
			right: -5%;
        }
        
        @media only screen and (max-width:1250px){
            right: -20%;
        }

		@media #{$tablet}{
            right: -25%;
			top: -500px;

			width: 800px;
			height: 800px;
		}

		@media #{$mobile}{
			top: -150vw;
			right: -55vw;

			width: 165vw;
			height: 165vw;

			transform: rotate(40deg);
        }

		@media #{$cell}{
			top: -130vw;
			right: -55vw;

			width: 165vw;
			height: 165vw;

			transform: rotate(40deg);
        }
        


		img{
			position: absolute;
			right: 0;
			bottom: 0;

			transform: rotate(-45deg) translate3d(50%,0,0);
			transform-origin: bottom right;
			animation: play .6s infinite;

			@media #{$tablet}{
				width: auto;
				max-height: 450px;
			}

			@media #{$mobile}{
				right: -29vw;
                max-height: none;
				width: 70vw;
				bottom: 15vw;
				transform: rotate(-40deg);
			}

			@media #{$cell}{
				right: -34vw;
                max-height: none;
				width: 90vw;
				bottom: 60px;
				transform: rotate(-40deg);
			}
		}
	}


	.triangle__subtitle p{
		position: absolute;
		bottom: -40px;
		left: 0;

		width: 100%;
		height: 30px;

		font-size: 13px;
		text-transform: uppercase;

		@media #{$mobile}{
			display:none;
		}
	}

	.triangle__background,
	.triangle__subtitle{
		position: absolute;
		z-index: 1;
		top: -400px;
		right: -5%;

		// width: 1300px;
        // height: 1300px;
        width: 800px;
		height: 800px;

		transform: rotate(-45deg);

		@media #{$bigDesktop}{
			right: 0%;
		}

		@media #{$hugeDesktop}{
			right: 0;
		}

		@media #{$smallDesktop}{
			right: -5%;
        }

        @media only screen and (max-width:1250px){
            right: -20%;
        }

		@media #{$tablet}{
            right: -25%;
			top: -500px;

			width: 800px;
			height: 800px;
		}

		@media #{$mobile}{
			top: -150vw;
			right: -55vw;

			width: 165vw;
			height: 165vw;

			transform: rotate(-50deg);
		}

		@media #{$cell}{
			top: -130vw;
			right: -55vw;

			width: 165vw;
			height: 165vw;

			transform: rotate(-50deg);
		}
	}

	.triangle__text{
		position: absolute;
		z-index: 2;

		width: 100vw;
        // margin-top: 60px;
        pointer-events: none;
        top:70px;
		@media #{$tablet}{
			// margin-top: 30px;
		}

		h1:last-of-type{
			position: relative;

			margin-top: -60px;
			padding-left: 290px;

			@media #{$cell}{
				margin-top: -30px;
				padding-left: 90px;
			}
		}
	}

	.triangle__text--wrapper{
		position: absolute;

		display: block;

		opacity: 0;

		&.clone{
			opacity: 1;
        }
        
        @media #{mobile}{
           
        }
	}

	.triangle__text h1{
		position: relative;

		font-family: $avantGarde;
		font-size: 200px;
        font-weight: 500;
        top:-50px;

		white-space: nowrap;

		@media #{$tablet}{
			font-size: 200px;
		}

		@media #{$mobile}{
			font-size: 150px;
		}

		@media #{$cell}{
			font-size: 100px;
		}
	}
}


.deepdive__introduction{
	position: relative;
	z-index: 20;
    // padding-top: 95px;

    min-height: 700px;
    top:80px;

	border-bottom-right-radius: 20px;
	border-bottom-left-radius: 20px;
	background-color: #fff;
    box-shadow: 10px 30px 41px -15px rgba(0, 0, 0, .25);

    @media #{$mobile}{
        padding-top: 95px;
	}
	
	@media #{$cell}{
		pointer-events: none;
	}

	.introduction__triangle{
		position: absolute;
		top: -470px;
		left: 50%;

		overflow: hidden;
		width: 800px;
		height: 800px;

		opacity: .15;
		transform: translateX(-50%) rotate(45deg);

		img{
			position: absolute;
			bottom: -150px;
			left: calc(50% + 150px);

			max-width: none;

			transform: translateX(-50%) rotate(-45deg);
		}
	}

	.introduction__wrapper{
		position: relative;

		width: 100%;
		max-width: $container-content-width;
		margin: auto;
		padding: 100px 40px 100px;

		@media #{$mobile}{
			padding: 0px 20px 100px;
        }
        
	}

	.introduction__subtitle{
		display: inline-block;

		font-size: 30px;
		text-transform: uppercase;
        .ie.fallback &{
            background:none !important;
        }
		@media #{$mobile}{
			width: 100%;
		}
	}

	.introduction__title{
		width: 70%;
		margin-top: 20px;
		margin-bottom: 20px;

		font-size: 80px;
		font-weight: 400;
        line-height: 1.2em;
        .ie.fallback &{
            background:none !important;
        }
		@media #{$mobile}{
			width: 100%;

			font-size: 40px;
		}
	}

	.introduction__text-title{
		width: 50%;
		max-width: 550px;
		margin-bottom: 20px;

		font-size: 20px;
		font-weight: 700;
		line-height: 1.5em;
		color: #656565;

		letter-spacing: 1px;
		@media #{$mobile}{
			width: 100%;
		}
	}

	.introduction__image{
		position: absolute;
		bottom: 0;
		left: 78%;

		width: 600px;

        transform: translateX(-50%);
        
        .legend{
            position:absolute;
            bottom:10px;
            width:400px !important;
            text-align:right !important;
            left: -415px;
        }
		@media #{$mobile}{
			display: none;
		}
	}

	.introduction__text{
		width: 50%;
		max-width: 550px;

		font-size: 16px;
		font-weight: 300;
		line-height: 2em;
		color: #656565;

		@media #{$mobile}{
			width: 100%;
		}
	}
}



/* -----------------------*/
/* [EXP] ==== Exception
/*------------------------*/
// .view__deepdive--from-colony-to-devided-country{
// 	.triangle__text{
// 		margin-top: 80px;

// 		@media #{$mobile}{
// 			margin-top: 20px;
// 		}
// 	}

// 	.triangle__text h1{
// 		font-size: 115px;
// 	}

// 	.triangle__text h1:first-of-type{
// 		font-size: 350px;

// 		@media #{$tablet}{
// 			font-size: 200px;
// 		}

// 		@media #{$mobile}{
// 			font-size: 150px;
// 		}
// 	}
// }

@media #{$notMobile}{
	.deepdive__slide:not(.loaded):first-of-type{
		.deepdive__intro{
			opacity: 0;
			transform: translateX(-50px);
		}

		.img-wrapper{
			opacity: 0;
		}

		.triangle__wrapper .triangle__subtitle p{
			opacity: 0;
			transform: translateX(40px);
		}

		.triangle__background{
			opacity: 0;
			transform: translateX(70px) rotate(-45deg);

			@media #{$mobile}{
				transform: translateX(0px);
			}
		}
	}
}


.deepdive__container.view__deepdive{
    .legend{
        font-size:13px;
        text-transform: uppercase;
        text-align:left;
        width:100%;
        display: block;
        margin-top:5px;
    }
}

.deepdive__introduction + .deepdive__slide{
    padding-top: 80px;
    
    .triangle__text{
        top:50px;

        @media #{$mobile}{
            top: 50px;
        }
    }
}