.view__glossary{

	// fallback
	body.fallback & {
		background-size: cover;
	}
	
	.glossary__list{
		width:100%;
		overflow:hidden;
	}
    
	.list__top{
		align-items: flex-start;

		display: flex;

		font-size: 0;

		.list__left{
			align-items: center;

			display: inline-flex;
			width: 20%;

			font-size: 25px;

			transform: translateY(20px);

			@media #{$cell}{
				transform:translateY(8px);
			}

			.list__number{
				color: #fff;
			}

			.h-line{
				display: inline-block;
				width: 25%;
				height: 2px;
				margin: 0 25%;

				background-color: #fff;
				transform: translateY(-3px);
			}
		}

		.list__right{
			position: relative;

			display: inline-block;
			width: 80%;

            font-size: 20px;
            
            

			h2,
			.bg-text{
				position: relative;
				z-index: 2;
				top: 10px;

				font-size: 55px;
				font-weight: 500;
				line-height: 1em;
				color: #fff;

				letter-spacing: 1.5px;

				@media #{$mobile}{
					font-size: 30px;

					transform: translateY(12px);
                }

                @media #{$cell}{
                    font-size:26px;
                    margin-bottom:10px;
                }
			}

			.bg-text{
				position: absolute;
				z-index: 1;
				top: 10px;
				left: 0px;

				color: yellow;
				transform: translate(5px,0);
				
				body:not(.ie) &{
					background: repeating-linear-gradient(rgba(222,60,117,.3), rgba(222,60,117,.3) 2px, $c-primary 4px, $c-primary 4px);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}

				body.ie &{
					color:rgba(222,60,117,.6);
				}
				
			}
		}
	}

	.list__bottom{
		display: flex;

		font-size: 0;

		.list__left{
			display: inline-block;
			width: 20%;

			.v-line{
				width: 2px;
				height: 100%;

				opacity: .15;
				background-color: #fff;
				transform: translate(12px,4px);

				@media #{$mobile}{
					height: 80%;

					transform: translate(12px,20px);
				}
			}
		}

		.list__right{
			display: inline-block;
			width: 80%;

			p{
				margin-bottom: 50px;

				font-family: $avantGarde;
				font-size: 16px;
				font-style: italic;
				line-height: 1.6em;

				transform: translateY(10px);

				@media #{$mobile}{
					font-size: 14px;

					transform: translateY(15px);
				}
			}
		}
	}

	&:not(.loaded){
		.list__wrapper{
			opacity: 0;

			@media #{$cell}{
				display:flex;
				flex-direction:column;
			}

			.list__left .h-line{
				transform: scaleX(0) translateY(-3px);
				transform-origin: left;
			}

			h2,
			.list__right .bg-text{
				transform: translate(50px);
			}

			.list__left .v-line{
				transform: translate(12px,4px) scaleY(0);
				transform-origin: top center;
			}

			.list__right p{
				opacity: 0;
				transform: translateY(30px);
			}
		}
		
		.main__content h1{
			opacity: 0;
			transform: translateY(-60px);
		}
	}
}
