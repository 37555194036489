.middledive__slider--faces{
	position: absolute;
	z-index: 2;
	bottom: 0;
	left: 0;

	width: 100%;

	font-size: 0;

	opacity: .3;
	transform: translateY(130px);

	.faces{
		position: absolute;
		bottom: 0;

		transition: transform .3s ease;
		transform: translateY(10px);

		&.active{
			transform: translateY(0px);
			svg.translateItem{
				transform: translateX(20px) scale(1);
			}
		}

		.translateItem{
			pointer-events:none;
		}

		svg{
			position: absolute;
			z-index: 1;
			left: 0;

			transition: transform .3s ease;
			pointer-events: none;
		}

		svg path{
			transition: transform .3s ease;
			transform: scaleX(.9);
			transform-origin: bottom;
			pointer-events: auto;
		}

		img{
			position: relative;
			z-index: 2;

			max-width: none;

			pointer-events: none;
		}

		&.faces__1{
			left: 0;
		}
		&.faces__2{
			left: 200px;
		}
		&.faces__3{
			left: 400px;
		}
		&.faces__4{
			left: 600px;
		}
		&.faces__5{
			left: 800px;
		}
		&.faces__6{
			left: 1000px;
		}
		&.faces__7{
			left: 1200px;
		}
		&.faces__8{
			left: 1400px;
		}
		&.faces__9{
			left: 1600px;
		}
		&.faces__10{
			left: 1800px;
		}
	}

	@media #{$mobile}{
		.faces{
			position: relative;
			left: auto !important;

			display: block;
			width: 100%;
			padding: 15px 25px;

			&:before{
				position: absolute;
				bottom: 0;
				left: 0px;

				width: 100%;
				height: 2px;

				opacity: .5;
				background-color: #fff;
				content: '';
			}
		}

		.faces p{
			font-size: 22px;

			color: #fff;
		}

		img,
		svg{
			display: none;
		}
	}
}
