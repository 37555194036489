/* ============================*/
/* ==[ Block intro ]== */
/*=============================*/
.block__intro{
	// MarginChange
	// margin-bottom: 100px;
    margin-bottom: 80px;

	lost-utility: clearfix;
	vertical-align: top;
	

	@media #{$mobile}{
		margin-bottom: 40px;
	}

	.intro__col{
		lost-column: 1/2 2 60px;

		@media #{$mobile}{
			lost-column: 1/1 1 0;
		}
		
		&.intro__col--left{
			margin-top: 300px;
		}

		.two-col__quote{
			@include bigQuote();
			padding-top: 30px;
		}

		img{
			width: 100%;
			margin-top: 250px;

			@media #{$mobile}{
				margin-top: 40px;
			}
        }
        
        .legend{
            opacity:0;
        }

        img + .legend{
            opacity:1;
        }
	}

	.two-col__video{
		margin-top: 300px;
	}

	.first-letter{
		display: inline-block;
		float: left;
		margin-right: 10px;

		font-size: 100px;
		font-weight: 700;
		line-height: 0.96em;
		text-transform: uppercase;

		@media #{$tablet}{
			font-size: 180px;
			line-height: 1em;
		}

		@media #{$mobile}{
			margin-right: 20px;
			line-height: .9em;
			font-size: calc(230px/2);
		}
	}

	p + .quote{
		margin-top: 60px;
	}

	.quote{
		@include bigQuote();
	}
}

.deepdive__intro{
    lost-utility: clearfix;
    text-align: left;
    position: relative;
    z-index: 3;
    // lost-column: 1/2 1 0;
    width:50%;

    a{
        color:#656565 !important;
        pointer-events: none;
    }

	@media #{$mobile}{
		width:100%;
	}
}

.deepdive__intro h3{
    font-size: 55px;
    background:none;
    display: inline-block;
    margin-bottom:30px;
    margin-top:10px;
    line-height: 1.3em;
    .ie.fallback &{
        background:none !important;
    }
	
	@media #{$mobile}{
		font-size:35px;
	}
}

.deepdive__intro h4{
    font-size: 30px;
    display: inline-block;
	padding-top: 120px;
    line-height: 1.3em;
    
    .ie.fallback &{
        background:none !important;
    }
    @media #{$tablet}{
		padding-top: 120px;
    }
    @media #{$mobile}{
		padding-top: 360px;
		font-size:26px;
    }
    @media #{$cell}{
        padding-top: 280px;
		font-size:26px;
    }
}

.deepdive__intro p{

    
	float: left !important;
	margin-bottom: 50px;
	
	padding-right: 20px;
	

	font-size: $fs-20;
	font-weight: 700;
	line-height: 1.5em;

    letter-spacing: .5px;
    
    &:last-of-type{
        margin-bottom:30px;    
    }

	@media #{$tablet}{
		padding-top: 0px;
	}

	@media #{$mobile}{
		margin-bottom: 40px;
		lost-column: 1/1;
	}

	@media #{$cell}{
		margin-bottom: 40px;
		lost-column: 1/1;

		font-size: 15px;
	}
}
