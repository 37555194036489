/**
 * Fonts
 */

$path : '../assets/fonts/';
$avantGarde: 'avantgarde';

@font-face {
    font-family: $avantGarde;
    font-weight: 700;
    src: url('#{$path}avantgarde-bold.eot');
    src: url('#{$path}avantgarde-bold.eot?#iefix') format('embedded-opentype'),
         url('#{$path}avantgarde-bold.woff2') format('woff2'),
         url('#{$path}avantgarde-bold.woff') format('woff'),
         url('#{$path}avantgarde-bold.ttf') format('truetype');
    -webkit-font-smoothing: auto;
	-moz-osx-font-smoothing: auto;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
}

@font-face {
    font-family: $avantGarde;
    src: url('#{$path}avantgarde-regular.eot');
    src: url('#{$path}avantgarde-regular.eot?#iefix') format('embedded-opentype'),
         url('#{$path}avantgarde-regular.woff2') format('woff2'),
         url('#{$path}avantgarde-regular.woff') format('woff'),
         url('#{$path}avantgarde-regular.ttf') format('truetype');
}

// ------
// ITALIC FONT ONLY IN BOLD

// @font-face {
//     font-family: $avantGarde;
//     font-style: italic;
//     src: url('#{$path}avantgarde-regular-italic.eot');
//     src: url('#{$path}avantgarde-regular-italic.eot?#iefix') format('embedded-opentype'),
//          url('#{$path}avantgarde-regular-italic.woff2') format('woff2'),
//          url('#{$path}avantgarde-regular-italic.woff') format('woff'),
//          url('#{$path}avantgarde-regular-italic.ttf') format('truetype');
// }

@font-face {
    font-family: $avantGarde;
    font-weight: 300;
    src: url('#{$path}avantgarde-thin.eot');
    src: url('#{$path}avantgarde-thin.eot?#iefix') format('embedded-opentype'),
         url('#{$path}avantgarde-thin.woff2') format('woff2'),
         url('#{$path}avantgarde-thin.woff') format('woff'),
         url('#{$path}avantgarde-thin.ttf') format('truetype');
}