.view__home{
	@include viewportBox;

	cursor: $grab;

	.sliding & {
		cursor: $grabbing;
	}

	.cursor--loading & {
		cursor: none; // leave the loading cursor ALONE !
    }
    .home__button{
        padding: 12px 20px;
    }
}

body:not(.fallback) .view__home{
	overflow: hidden;
}

.home__list{
	@include absoluteBox;
}

.home__item{
	pointer-events: none;

	@include absoluteBox;

	&.home__item--current {
		pointer-events: auto;

		&.home__item--focus {
			pointer-events: none;
		}
	}
}

.home__link{
	position: absolute;
	top: calc(50% + 15vh + 60px);
	left: 50%;

	display: block;
	min-width: 80px;
	min-height: 80px;
	max-width: 110px;
	max-height: 110px;

	width: calc(100vw * 80 / 1440);
	height: calc(100vw * 80 / 1440);

	transform: translate(-50%, -50%) scale(1);
	transition: transform 0.3s $Power4EaseOut;
	transform-origin: 50% 50%;
	border-radius: 50%;
	// background: red;
	// box-shadow: 2px 0px 2px rgba(0, 255, 240, 0.08), -2px 0px 2px rgba(232, 35, 96, 0.08);
	// box-shadow: $distorsionShadow;

	opacity: 0;

	@media (min-aspect-ratio: 1 / 1 ) { // height > width
		width: calc(100vw * 80 / 1440);
		height: calc(100vw * 80 / 1440);
	}

	@media (max-aspect-ratio: 1 / 1 ) { // height > width
		width: calc(100vh * 90 / 900);
		height: calc(100vh * 90 / 900);
	}

	&:hover {
		transform: translate(-50%, -50%) scale(1.2);
	}
	
	.home__item--current & {
		opacity: 1;
	}
}

.home__button{
	position: absolute;
	right: 30px;
	bottom: 30px;
	z-index: 5;

	padding: 20px 30px;

	border-radius: 30px;
	visibility: hidden;
	opacity: 0;

	@media #{$mobile}{
		bottom:9%;
        padding: 15px 25px;
        right:20px;
        [lang="fr"] &{
            bottom:14%;
        }
	}


	&:before {
		content:'';
		border-radius: 30px;
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;

		background: white;

		transition: transform .175s ease-out;
	}

	.home__label {
		position:relative;
		font-size: 14px;
		text-transform: uppercase;
		color: #242424;

		&:after {
			content: '';
			position:relative;
			display: inline-block;
			width:16px;
			height:6px;

			top:-2px;
			margin-left: 10px;

			background-image:url('../assets/images/intro-bt-arrow.png');
			background-size:contain;
			
			opacity: .5;

			transition: opacity .25s ease-out;
		}
	}

	.home__label--skip {
		display: block;

		.skip__time {
			// position: absolute;
			// left: -16px;
			width: 100%;

			font-size: 14px;
			text-transform: uppercase;
			color: rgba(#242424, 1);
		}

		// &:hover {
		// 	color: rgba(#242424, 0);

		// 	.skip__time {
		// 		color: rgba(#242424, 1);
		// 	}
		// }
        @media #{$mobile}{
            font-size:13px;
        }
		
	}

	.home__label--again {
		display: none;
	}

	&:hover {
		&:before {
			transform: scale(1.1);
		}
		.home__label:after {
			opacity: 1;
		}
	}
}

.home__button.home__button--again {

	right: 10px;
	bottom: 10px;

	&:before {
		display: none;
	}

	.home__label--skip {
		display: none;
	}

	.home__label--again {
		display: block;
		color:#fff;

		transition: color .25s ease-out;

		&:after {
			background-image:url('../assets/images/intro-bt-arrow-white.png');
		}

		&:hover {
			color:#ccc;
		}
	}
}

.home__track {
	visibility: hidden;
	opacity: 0;
}

.home__cards{
	display: block;
	overflow-x: visible;
	overflow-y: hidden;

	.home__cards__item{
		position: absolute;

		overflow: hidden;

		border-radius: 25px;
		box-shadow: 0px 0px 16px 5px rgba(0,0,0,.2);
		transform-origin: center;

		img{
			display: block;

			object-fit: cover;
		}
	}

	.home__cards__inner{
		position: absolute;

		display: flex;

		p{
            color: #fff;
            
            br{
                display:none;
            }
		}
	}

	.home__cards__how {
		display: block;

		color: #fff;

		&--small {
			font-size: 14px;
		}

		&--big {
			font-size: 23px;
		}
	}

	.home__cards__label{
		display: flex;
		padding: 10px 24px 8px 24px;

		font-size: 14px;
		text-transform: uppercase;
		color: #349ab4;

		border-radius: 30px;
		background-color: #fff;

		svg {
			width: 11px;
			height: 20px;
			margin-right: 10px;
			margin-top: -2px;

			fill: #349ab4;
		}
	}

}

body:not(.fallback) .home__cards{
	position: absolute;
	bottom: 0;
	left: calc(12.5% - 150px);

	width: 300px;
	height: 300px;

	transition: transform .5s $ExpoEaseOut;
	transform: translate3d(0, 400px, 0);

	&.home__cards--show{
		transform: translate3d(0, 80px, 0);
	}

	.home__cards__item{
		left: 50%;

		width: 210px;
		height: 250px;
		margin-left: -105px;

		transition: transform .3s $Power4EaseOut;

		img{
			display: block;
			height: 100%;

			object-fit: cover;
		}

		&:nth-child(1){
			transform: scale(0.9) rotate(10deg) translate3d(55px, 50px, 0);
		}

		&:nth-child(2){
			transform: scale(0.9) rotate(-10deg) translate3d(-55px, 50px, 0);
		}

		&:nth-child(3){
			transform: scale(0.9) rotate(-5deg) translate3d(-30px, 20px, 0);
		}

		&:nth-child(4){
			transform: scale(0.9) rotate(5deg) translate3d(30px, 20px, 0);
		}
	}

	.home__cards__inner{
		left: 50%;
		align-items: center;
		justify-content: center;

		flex-direction: column;
		width: 210px;
		height: 250px;
		margin-left: -105px;
		padding: 20px 0px;

		p{
			margin-bottom: 20px;

			font-size: 23px;
			color: #fff;

			text-align: center;
		}
	}
}

body:not(.fallback).sliding .home__cards {
	pointer-events: none;

	&.home__cards--show{
		transform: translate3d(0, 150px, 0);
	}
}

body:not(.fallback) .home__cards:hover{
	transform: translate3d(0, 70px, 0);

	// .home__cards__item{
	// 	transform: scale(1);

	// 	&:nth-child(1){
	// 		transform: scale(1) rotate(5deg) translate(30px,30px);
	// 	}

	// 	&:nth-child(2){
	// 		transform: scale(1) rotate(-5deg) translate(-30px,30px);
	// 	}
	// }
}


.fallback .home__cards{
	width: 100vw;

	.home__cards__item{
		left: 10%;

		width: 80%;
		max-width: 800px;

		&:nth-child(1){
			transform: rotate(5deg) translate3d(20px,30px, 0);
		}

		&:nth-child(2){
			transform: rotate(-5deg) translate3d(-20px,30px, 0);
		}
	}

	.home__cards__inner{
		align-items: center;
		justify-content: center;

		display: flex;
		box-sizing: border-box;
		width: 100%;
		height: 100%;
		padding: 20px 0px;

        flex-direction:column;
        
        @media #{$tablet}{
            padding:10px 0;
        }

		p{
			margin-bottom: 10px;
			text-align: center;
			font-size: 19px;
		}
	}

	.home__cards__label{
		margin-left: 20px;

		@media #{$tablet}{
			margin-left: 0px;
			padding: 18px 24px 16px 24px;
			align-items:center;
		}

		.fallback & {
			margin-left: 0px;
			padding: 18px 24px 16px 24px;
			align-items:center;
		}
	}
}

.mobile__swiper{
	display: none;
}

/**
 * FALLBACK
 */

.ie.fallback .view__home{
    height:100vh;    
}

.fallback .view__home{
	// reset
	/*.home__list,
	.home__skip{
		display: none;
    }*/

    video::cue {
        color: white;
        padding:5px;
        font-size:20px;
        position:relative;
        background-color: rgba(0, 0, 0, 0.6);
    }
    
    height:100%;
    // height:calc(100vh - 47px);
    // overflow: hidden;
    // top:45px;

    // &.pov-fallback{
    //     height:100vh;
    // }

    .poster-intro{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background-size:cover;
        background-position:center center;
    }

    .swiper-pagination-bullet{
        background: #fff;
        padding: 10px;
        margin: 0 10px;
    }

	.mobile__swiper{
		display: block;
		width: 80vw;
		max-width: 820px;
        margin: 185px auto 40px auto;
        
        @media #{$tablet}{
            margin: 165px auto 40px auto;
        }

		@media #{$mobile}{
			margin: 140px auto 40px auto;
        }
        
        


		.swiper-wrapper{

			opacity: 0;
            visibility: hidden;
            
            // @media #{$tablet}{
                height:49vh;
                min-height:300px;
                max-height:480px;
            // }

            @media #{$mobile}{
                height:60vh;
                min-height:0px;
            }

			@media #{$cell}{
				height: 45vh;
                
				.swiper-slide img{
					height: 100%;
				}
            }
            
            @media only screen and (max-height:560px){
                height: 40vh;
            }

            
            @media only screen and (max-height:480px){
                height: 30vh;
            }


			.swiper-slide{
				overflow: hidden;

				border-radius: 25px;
				background-position: center;
				background-size: cover;

				.perso{
					display: block;
					height: 450px;
                    margin: 30px auto 0 auto;
                    
                    // @media #{$tablet}{
                        max-width: none;
                        transform: translate(-50%,-50%);
                        left: 50%;
                        top: 50%;
                        position: absolute;
                        height: 130%;
                    // }

					@media #{$mobile} {
						height:600px;
					}

					@media #{$cell} {
						height: 130%;
                    }
                    
                    @media only screen and (max-height:480px){
                        height: 160%;
                    }
				}

				.sign{
					position: absolute;
					// top: 22%;
					left:50%;
                    // width:120%;
                    height: auto;
                    top:54%;
                    transform:translate(-50%,-50%);
                    max-width:none;

					// @media #{$tablet} {
                        top:48%;
                        width:90%;
						// top: 30%;
						// left:0;
						// width:auto;
                    // }
                    
                    @media #{$mobile}{
                        top:54%;
                        width:120%;
                    }

					@media #{$cell} {
						// top:30%;
						// height: auto;
                    }
                    @media only screen and (max-height:480px){
                        top:45%;
                    }
				}

				.play{
					position: absolute;
					top: calc(70%);
					left: calc(50% - 47px);

					width: 94px;
					height: 87px;

					img{
						display: block;
                    }
                    
                    @media #{$tablet}{
                        top:74%;
                    }

                    @media #{$mobile}{

                    }

					@media #{$cell} {
						top: calc(70%);
						left: calc(50% - 23px);

						width: 47px;
						height: 43px;
					}
				}
            }
		}
	}

	video {
		@include absoluteBox;
        height:100%;
        
		// top:45px;
		z-index: 2;

		.mobile.safari {
			height:calc(100vh - 90px);
		}

        object-fit: cover;
        
        @media #{$mobile}{
            height:auto;
            transform:translateY(-50%);
            top:50%;
        }
	}

	.home__skip{
		z-index: 3;
	}

	.swiper-pagination{
		position: relative;

        margin: 20px auto 10px auto;
        bottom: 0px;

        opacity:0;
	}

	.home__cards{
		position: relative;

		display: block;
		max-width: 520px;
		height: calc(100vh - 600px);
		min-height: 150px;
		margin: auto;

		opacity: 0;
        visibility: hidden;
        
        display:none;

        height:calc(35vh - 94px);

        .home__cards__how{
            display:inline-block;
        }

        br{
            display:block;
        }

        @media #{$cell}{
            .home__cards__how--big{
                font-size:20px;
            }
        }

        @media #{$tablet}{
            height:130px;
            min-height:130px;
            transform:translateX(-50%);
            left:50%;
            margin:0;
            br{
                display:block;
            }

            .home__cards__how{
                line-height: 1em;
                
            }

            .home__cards__label{
                padding:7px 14px 7px 14px;
                line-height: 1em;
                svg{
                    display:none;
                }
            }
        }

		@media #{$mobile} {
            // max-height: 220px;
            height:130px;
            min-height:130px;
			position: absolute;
    		bottom: 0;
		}

		@media #{$cell}{
			// height: 190px;
            height:110px;
            min-height:110px;
			&__inner{
				flex-direction: column;

				p,
				.cta{
					margin: auto;
				}
			}
		}


	}
}

.scroll-content{
    height:100%;
}

// #root{
//     height:100vh;
// }