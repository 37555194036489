$easing: $Power4EaseOut;
$duration: .5s;

.view__pov{
    overflow: hidden;
    height: 100vh;

    @include absoluteBox;

    // &.ui--hidden {
    //     pointer-events: none;
    // }
    
    @media #{$tablet} {
        padding-top: 90px;
    }

    .button__bkg {
        // display: none; // TEMP
    }

    .ie &{
        width:100vw;
    }
}

.pov__timeline{
    position: absolute;
    bottom: 0;
    left: 0;

    display: flex;
    // display: none; //TEMP
    width: 100%;
    margin-bottom: 8vh;
    padding: 0 20px;

    transition: opacity $duration $easing $duration;
    transform: translate3d(0, 0, 0); // GPU acceleration
    
    .ui--hidden & {
        opacity: 0;
        pointer-events: none;

        transition: opacity $duration $easing;
    }
}

.pov__timeline.pov__timeline--hidden{
    opacity: 0;
    pointer-events: none;

    transition: opacity $duration $easing ;
}

.pov__chapter{
    position: relative;

    width: 20%;
    margin: 0 5px;
    padding-bottom: 35px;

    cursor: $pointer;
    text-align: center;

    .chapter__title{
        display: block;
        margin-bottom: 20px;

        color: white;
        font-size: 20px;
        line-height: 20px;
        font-weight: 700;

        opacity: 0;
        text-shadow: -2px 0px 15px rgba(0, 0, 0, 0.5);
    }

    .timeline__bkg{
        position: relative;

        width: 100%;
        height: 2px;

        background-color: rgba(white, .3);
        transform-origin: 100% 50%;

        &:after{
            opacity: 0;
            background-color: rgba(white, 1);
            content: '';

            @include absoluteBox;
        }
    }
}

.pov__chapter.pov__chapter--active{
    .chapter__title{
        color: $c-pink;
    }
}

.pov__nav{
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 55vh;

    transform: translate3d(0, -100%, 0);
    pointer-events: none;
}

.pov__nav.pov__nav--active{
    transform: translate3d(0, 0, 0);
    pointer-events: auto;
}

.pov__list{
    display: flex;
    width: 100%;
    height: 100%;
}

.pov__item{
    align-items: center;
    justify-content: center;

    display: flex;
    width: 25%;
    height: 100%;

    .pov__link{
        width: 100%;
        height: 100%;

        outline: 0;
    }

    .pov__label{
        display: none;
    }

    &.pov__item--hidden{
        display: none;
    }
}

.timeline__progress{
    display: flex;

    opacity: 0;

    .progress__step{
        position: relative;

        display: block;
        width: calc(100% / 30);
        height: 11px;
        margin: -6px 1px;

        background-color: $c-pink;
        // transition: transform .5s $easing;
        transform: scaleY(1);

        &.progress__step--hidden{
            transform: scaleY(0);
        }

        &.progress__step--last{
            transform: scaleY(1.3);
        }

        &:after{
            box-shadow: 0px 0px 20px 0px rgba(255,52,143,1);
            content: '';

            @include absoluteBox;
        }
    }

    .pov__chapter--active &{
        opacity: 1;
    }
}

.pov__toggle{
    position: absolute;
    z-index: 30;
    top: 65px;
    left: 50%;

    // display: none; //TEMP
    
    opacity: 1;
    transform: translate3d(-32px, 0, 0);
    transition: transform $duration $Power4EaseOut, opacity $duration $Power4EaseOut;

    &.pov__toggle--hidden {
        visibility: hidden;
    }

    .button__icon {
        svg {
            width: 26px;
            height: 18px;
        }
    }

    .ui--hidden & {
        opacity: 0;

        .button__hit-area { 
            pointer-events:none;
        }
    }
}

.button.open .button__icon{

    .readmore__line {
        transform: scale(0,1);
    }
    svg {
        transform: scale(0,0);
    }
}

.pov__chapter:hover,
.pov__chapter.pov__chapter--active{
    .timeline__bkg:after{
        opacity: 1;
    }

    .chapter__title{
        opacity: 1;
    }
}

.pov__deepdives{
    position: absolute;
    bottom: 0;
    left: 0;
    justify-content: center;

    display: flex;
    width: 100%;
    height: 55vh;

    transition: transform $duration $easing, opacity $duration $easing;
    transform: translate3d(0, 100%, 0);

    .deepdives__toggle{
        position: absolute;
        z-index: 30;
        top: -70px;
        left: 50%;

        transform: translate3d(0, 0, 0);
    }

    .ui--hidden & {
        pointer-events: none;
        opacity: 0;
    }
}

.pov__deepdives--active{
    transform: translate3d(0, 0, 0);

    transition: transform $duration $easing 0.1s;
}

.pov__deepdive {
    position: absolute;
    bottom: -50px;

    // display: none; //TEMP
    height: 45vh;
    
    text-align: center;
    

    .deepdive__black-white, .deepdive__duotone {
        @include absoluteBox;
        
        // transition: transform $duration $easing;
        transform: translate3d(0, 0, 0);

        img {
            border-radius: 25px 25px 0 0;
            top: 0;
            left: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .deepdive__duotone {
        opacity: 0;
        transition: opacity 0.5s ease;
    }

    .deepdive__link {
        position: absolute;
        top: 0;
        left: 0;
        align-items: center;
        justify-content: center;
        
        display: none;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 0 20px;

        text-decoration: none;

        &.deepdive__link--active {
            display: flex;

            &:hover {
                .deepdive__duotone {
                    opacity: 1;
                }
            }
        }
    }

    .deepdive__title{
        margin-top: -50px;

        font-size: 26px;
        text-transform: uppercase;
        color: white;

        transition: transform $duration $easing;
        transform: translate3d(0, 0, 0);
        letter-spacing: .02em;
    }

    .deepdive__subtitle{
        font-size: 22px;
        color: white;

        transition: transform $duration $easing;
        transform: translate3d(0, 0, 0);
    }
}

.pov__deepdive--chapter {
    left: 10%;
    width: 53vw;

    .deepdive__title {
        font-size: 43px;
    }
}
.pov__deepdive--pov {
    right: 10%;

    width: 20vw;

    .deepdive__black-white, .deepdive__duotone {
        img {
            border: 2px solid white;
        }
    }
}

.pov__deepdive:hover {
    
    .deepdive__title {
        transform: translate3d(0, -10px, 0);
    }

    .deepdive__subtitle {
        transform: translate3d(0, -10px, 0);
    }

    .deepdive__canvas {
        transform: translate3d(0, -10px, 0);
    }
}



.track {
    position: absolute;
    left: 0;
    bottom: calc(8vh);

    width: 100%;
    padding: 10px 18%;

    text-align: center;

    pointer-events: none;
    // user-select: none;

    .track__text {
        margin: 0 auto;

        color: white;
        font-size: 25px;
        letter-spacing: 0.03em;
        line-height: 1.48em;
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

        transition: opacity 0.3s $Power4EaseOut;
    }
}

.track.pov__track {
    //display: none; //TEMP

    bottom: calc(8vh + 105px);
    transform: translate3d(0, 0, 0);

    transition: transform $duration $Power4EaseOut;

    &.pov__track--top {
        opacity: 0;


        // transition: transform 0.3s $Power4EaseOut;
    }

    &.pov__track--bottom {
        opacity: 0;

        // transition: transform 0.3s $Power4EaseOut;
    }

    .ui--hidden & {
        transform: translate3d(0, 105px, 0);
    }
}

.pov__end {
    @include absoluteBox;

    display: flex;
    
    opacity: 0;
    visibility: hidden;

    .end__side {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        display: flex;
        width: 50%;
        height: 100%;

        text-align: center;

        visibility: hidden;
        opacity: 0;
    }

    .end__subtitle {
        color: white;
        font-size: 14px;
        text-transform: uppercase;
    }

    .end__deepdive {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        display: none;

        &.end__deepdive--active {
            display: flex;
        }
    }

    .end__title {
        margin: 30px 0;
        padding: 0 13%;

        color: white;
        font-size: 55px;
        line-height: 1em;
    }

    .end__link {
        position: relative;

        display: block;

        text-decoration: none;

        transition:transform .4s ease;
        transform:scale(1);

        *{
            transform:translateZ(0);
        }

        .desktop &:hover{
            transform:scale(1.1);
        }
        
        .button__label {
            color: rgba(0, 0, 0, 1);
        }
    }

    .end__play {
        display: block;
        min-width: 80px;
        min-height: 80px;
        max-width: 110px;
        max-height: 110px;
        width: calc(100vw * 80 / 1440);
        height: calc(100vw * 80 / 1440);
        margin-bottom: 30px;

        @media (min-aspect-ratio: 1 / 1 ) { // height > width
            width: calc(100vw * 80 / 1440);
            height: calc(100vw * 80 / 1440);
        }

        @media (max-aspect-ratio: 1 / 1 ) { // height > width
            width: calc(100vh * 90 / 900);
            height: calc(100vh * 90 / 900);
        }
    }

    .end__next {
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        display: flex;
        top: 24.5%;

        text-decoration: none;

        &:hover .end__play {
            transform: scale(1.2);
        }
    }

    .end__continue {
        display: block;

        color: white;
        text-transform: uppercase;
        text-shadow: $distorsionShadow;
    }
}

.mobile__pov{
    display: none;
}

/**
 * FALLBACK
 */
.fallback{

    video::cue {
        color: white;
        // font-weight: bold;
        padding:5px;
        font-size:26px;
        position:relative;
        background-color: rgba(0, 0, 0, 0.6);
        line-height:1.5em;
        @media #{$mobile}{
            line-height:1.5em;
        }
    }

    .btn-play-fallback{
        position: absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        display:block;
        width: 94px;
        height: 87px;
        z-index:22;
    }
    .view__pov{
        overflow: visible;
        opacity:0;
        padding-top:90px;
    }

    &.ie{
        .deepdive__black-white{
            left:0;
            top:0;
        }
        .read__more{ 
            &__item{
                flex-basis: calc(47% - 10px);
            }
        }
    }
    .deepdive__black-white{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        

        img{
            object-fit: cover;
            font-family: 'object-fit: cover;';
            width: 100%;
            height: 100%;
            .ie &{
                left:0;
                top:0;
            }
        }
    }

    // reset
    .pov__toggle,
    .pov__nav,
    .pov__timeline,
    .pov__deepdives{
        display: none;
    }

    .mobile__pov{
        display: flex;
        max-width: 1022px;
        margin: auto;

        flex-direction: column;
        align-items: center;


        

        h3,
        h4{
            display: block;

            color: #fff;

            text-align: center;
        }

        h3{
            margin-top: 65px;

            font-size: 35px;
        }

        h4{
            margin-bottom: 65px;

            font-family: $avantGarde;
            font-size: 15px;
            font-weight: 300;
        }

        &__player {

            position: relative;
            margin-top: 100px;

            max-width:680px;
            width:90%;


            @media #{$mobile}{
                width:90%;
            }
            @media #{$cell}{
                width:100%;
            }

            video{
                width:100%;
            }

            &__ctrl {
                position:absolute;
                bottom: 55px;
                width:calc(100% - 50px);
                left: 25px;

                display: flex;
                justify-content: space-between;
                align-items: center;

                @include autoAlpha(0);

                @media #{$cell} {
                    bottom:25px;

                    width:calc(100% - 30px);
                    left: 15px;
                }

                .timeline {
                    width:calc(100% - 50px);

                    @media #{$cell} {
                        width:calc(100% - 35px);
                    }

                    &__progress {
                        opacity: 1;
                    }
                }

                .timeline__background {
                    width: 100%;
                    height: 2px;
                    transform: translate3d(0, 0, 0);

                    &:after {
                        content: '';
                        position:absolute;
                        background-color: #fff;
                        width:100%;
                        height:2px;
                        top:-2px;
                        left: 0;
                    }

                    transform-origin: right center;
                }

                .fs-button {
                    img {
                        display: block;
                        pointer-events:none;
                    }
                }
            }

            .play-button,
            .pause-button {
                display: block;
                position:absolute;
                left:calc(50% - 49px);
                top:calc(50% - 46px);
                width:99px;
                height:92px;
                
                img {
                    display: block;
                    pointer-events:none;
                    opacity: .6;
                    transition: opacity .2s ease;
                }

                .desktop &:hover img {
                    opacity: 1;
                }

                @media #{$cell} {
                    left:calc(50% - 24px);
                    top:calc(50% - 23px);
                    width:49px;
                    height:46px;

                    img {
                        opacity: .93;
                    }
                }

            }

            .pause-button {
                
                transform: scale(1.2,1.2);
                @include autoAlpha(0);
            }

            

            video {
                max-width: 100%;
            }
        }
    }
    
    .read__more{ 
        justify-content: space-between;

        display: flex;
        width: 80vw;
        max-width: 1022px;

        flex-wrap: wrap;

        &__item{
            align-items: center;
            justify-content: center;

            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            min-height: 235px;
            margin-bottom: 20px;
            padding: 10px;

            border-radius: 25px;
            // background-color: pink;

            flex-basis: calc(50% - 10px);

            position: relative;
            overflow: hidden;

            &:first-of-type{
                border:4px solid rgba($color: #ffffff, $alpha: .5)
            }

            &:before{
                content:'';
                display:block;
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                background-color:#000;
                opacity:.3;
                z-index: 1;
            }

            @media #{$mobile}{
                flex-basis: 100%;
            }

            .label{
                font-size: 40px;
                line-height: 40px;
                text-transform: uppercase;
                color: #fff;
                z-index: 10;

                text-align: center;

                @media #{$mobile}{
                    font-size:24px;
                    line-height:1.3em;
                }
            }

            .sub-label{
                font-size: 22px;
                font-weight: 300;
                color: #fff;
            }

            .cta{
                margin-top: 20px;
                padding: 0 25px;

                border-radius: 20px;
                background-color: #fff;
                z-index: 10;
                span{
                    font-size: 14px;
                    line-height: 28px;
                }
            }
        }
    }
}

