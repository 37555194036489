.gallery__wrapper{
	margin-bottom: 160px;
	lost-utility: clearfix;
	display: flex;
    width: 100%;
    justify-content: center;

    @media #{$mobile}{
        display:block;
    }

	div{
		lost-column: 1/4 4 0;

		@media #{$mobile}{
            lost-column: 1/2 2 0;
            img{
                width:100%;
            }
		}

		&:nth-of-type(1),
		&:nth-of-type(3){
			transform: translateY(80px);
        }
        .legend{
            padding-left:5px;
            width: 100%;
            display: block;
            @media #{$mobile}{
                display:none !important;
            }
        }
	}
}
